<template>
<Slice>
  <b-container class="slice-action"
               :class="classes">

    <b-img v-if="hasMainVisual"
           class="main-visual"
           :alt="slice.primary.headline"
           :src="slice.primary.action_visual.url">
    </b-img>

    <section class="content">

      <div v-if="hasMainHeadline"
           class="main-headline">
        <h2 class="display">{{slice.primary.headline}}</h2>
      </div>

      <div v-if="hasMainText"
           class="main-description">
        <TextView :text="slice.primary.description"
                  flat />
      </div>
      <div class="actions"
           v-if="hasActions">
        <SuperButton :variant="variant"
                     :link="link">
          <p>{{label}}</p>
          <Icon type="arrow"></Icon>
        </SuperButton>
      </div>
    </section>

  </b-container>
</Slice>
</template>

<script>
import TextView from "@/components/ui/TextView"
export default {
  name: "SliceAction",
  props: [ 'item', 'slice' ],
  components: {
    TextView
  },
  computed: {
    hasMainVisual() {
      let vis = this.slice.primary.action_visual
      return vis && vis.url ? true : false
    },
    hasMainHeadline() {
      return this.slice.primary.headline ? true : false
    },
    hasMainText() {
      if ( this.slice.primary.description ) {
        let t = this.slice.primary.description.reduce( ( acc, x ) => acc + x.text.length, 0 );
        return this.slice.primary.description.length > 0 && t > 0 ? true : false
      }
      return false
    },
    hasActions() {
      return this.label ? true : false
    },
    classes() {
      let tone = this.slice.primary.tone || 'normal'
      let c = [ tone ]
      if ( this.hasMainVisual ) {
        c.push( 'hasMainVisual' )
      } else {
        c.push( 'hasNoVisual' )
      }
      return c
    },
    filteredActions() {
      return this.slice.items.filter( a => a.label != null )
    },
    label() {
      if ( this.slice.slice_type == "petition" ) {
        return "Petition"
      } else {
        return this.slice.primary.button_label
      }
    },
    link() {
      if ( this.slice.slice_type == "petition" ) {
        return this.slice.primary.petition
      } else if ( this.slice.slice_type == "job_application" ) {
        let jobTitle = this.$prismic.richTextAsPlain( this.item.data.title )
        return {
          "link_type": "Web",
          "url": `mailto:jobs@hungerfreeamerica.org?subject=${jobTitle}`,
          "target": "_blank"
        }
      } else {
        return this.slice.primary.button_link
      }
    },
    variant() {
      let v = this.slice.primary.tone
      if ( v == 'normal' ) {
        return 'primary'
      } else if ( v == 'highlighted' ) {
        return 'primary'
      } else if ( v == 'urgent' ) {
        return 'primary'
      } else {
        return 'primary'
      }
    }
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.slice-action {
    display: grid;
    grid-template-columns: 1fr;
    overflow: hidden;

    &.normal {
        background: linear-gradient(90deg, theme-color-level(emphasis, -11) 0%, theme-color-level(emphasis, -11.5) 100%);
    }

    &.highlighted {
        background: theme-color-level(primary, -11.5);
        background: linear-gradient(0deg, theme-color-level(warning, -6) 0%, theme-color-level(warning, -3) 100%);
    }

    &.urgent {
        background: theme-color-level(primary, -11.5);
        background: linear-gradient(0deg, theme-color-level(danger, -3) 0%, theme-color-level(danger, 2) 100%);

        /deep/ * {
            color: white;
        }

    }

    &.hasMainVisual {
        padding: var(--pxxxxl);
        grid-gap: 2rem;
        @include swoosh;
        grid-template-columns: 1fr 2fr;

        .main-visual {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include swoosh;
            max-height: 10rem;
        }

        section.content {
            display: grid;
            align-content: center;
            align-items: center;
            grid-row-gap: 1rem;

        }
    }

    &.hasNoVisual {
        padding: var(--pxxxxl);
        @include swoosh-long;

        section.content {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-areas: "headline actions" "description actions";
            grid-column-gap: 2rem;
            grid-row-gap: 1rem;
            align-items: center;

            .actions {
                grid-area: actions;
            }

            .headline {
                grid-area: headline;
            }

            .description {
                grid-area: description;
            }
        }
    }

    .actions {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--p1);

        .action {
            display: grid;
            grid-gap: 1rem;
            justify-content: start;
            justify-items: start;
            align-items: start;
            align-content: start;

            .super-button {
                grid-area: action;
            }

            &.hasDescription {
                grid-template-areas: "text action";
                grid-template-columns: 1fr 1fr;
                grid-gap: var(--p6);
                padding-bottom: var(--p4);

                .text {
                    grid-area: text;

                    /deep/ > *:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &.noDescription {
                grid-template-areas: "action";
                grid-template-columns: 1fr;
                grid-gap: 0;
            }
        }

        .mobile & {
            .action {
                grid-template-areas: "text" "action";
                grid-template-columns: 1fr;
                grid-auto-flow: row;
                padding-bottom: var(--p4);
            }
        }
    }

    .mobile &,
    .tablet & {
        @include swoosh-long;

        &.hasMainVisual {
            padding: 3rem 5rem;
        }
    }

    .mobile & {

        &.hasMainVisual {
            padding: 3rem 2rem;
            grid-template-columns: 1fr;

            .main-visual {
                height: 10rem;
                grid-row: auto;
                grid-column: auto;
            }
            section.content {
                grid-template-columns: 1fr;
                grid-template-rows: auto;
                grid-auto-rows: auto;
                grid-template-areas: "headline" "description" "actions";
            }
        }

        &.hasNoVisual {
            grid-template-columns: 1fr;
            padding: 3rem 2rem;

            section.content {
                grid-template-columns: 1fr;
                grid-template-rows: auto;
                grid-auto-rows: auto;
                grid-template-areas: "headline" "description" "actions";
            }
        }
    }

}
</style>
