<template>
<div class="petition"
     v-if="item">
  <sliceheadline :item="item" />
  <div class="accordion"
       :class="{'isExpanded':isExpanded}">
    <slicetext :text="text" />
    <div class="expand">
      <b-button variant="primary"
                @click="expand">Expand</b-button>
    </div>
  </div>
  <b-container>
    <b-card class="shadow"
            header="Click below for the submission form">

      <b-spinner v-if="isSigning" />

      <b-form v-if="!signed">
        <b-form-group label="Name"
                      v-if="collectName">
          <b-form-input v-model="name"
                        placeholder="Your full name" />
        </b-form-group>
        <b-form-group label="Email address"
                      v-if="collectEmail">
          <b-form-input v-model="email_address"
                        placeholder="Your email address" />
        </b-form-group>
        <b-form-group label="State"
                      v-if="collectState">
          <b-form-input v-model="state"
                        placeholder="Your state" />
        </b-form-group>
        <b-form-group label="Mailing address"
                      v-if="collectMailing">
          <b-form-textarea v-model="mailing_address"
                           rows="3"
                           placeholder="Your mailing address" />
        </b-form-group>
        <b-form-group v-for="field in otherFields"
                      :label="field.other_information"
                      :key="field.id">
          <b-form-input :id="field.other_information"
                        :placeholder="field.other_information" />
        </b-form-group>
        <section>
          <b-button size="md"
                    @click="openLink">Submission Form</b-button>
        </section>
        {{ url }}
      </b-form>
      <template v-else>
        <h3>Thank you for signing!</h3>
      </template>
    </b-card>
  </b-container>
</div>
</template>

<script>
import axios from "axios"
export default {
  name: "Petition",
  props: [ 'uid' ],
  created() {
    this.getContent()
  },
  data() {
    return {
      item: null,
      email: null,
      state: null,
      address: null,
      name: null,
      signed: false,
      isSigning: false,
      isExpanded: false,
      url: null,
    }
  },
  computed: {
    text() {
      return this.item.data.body
    },
    collectName() {
      return this.item.data.collect_name
    },
    collectEmail() {
      return this.item.data.collect_email_address
    },
    collectMailing() {
      return this.item.data.collect_mailing_address
    },
    collectState() {
      return this.item.data.collect_state
    },
    otherFields() {
      return this.item.data.other_fields
    },
  },
  methods: {
    getContent( lang ) {
      this.$prismic.client.getByUID( 'petition', this.uid, {
        lang: lang || this.language,
        fetchLinks: [ 'bio.portrait', 'bio.title', 'bio.job_title', 'bio.qualifications', 'bio.email', 'bio.telephone' ]
      } ).then( response => {
        if ( response ) {
          console.log(response)
          this.item = response
        }
      } )
    },
    expand() {
      this.isExpanded = true
    },
    openLink() {
      window.open(this.item.data.submission_url.url, '_blank');
    },
    async submitPetition() {
      // Show spinner
      this.isSigning = true

      // TODO: Validate content...
      // TODO: Send to salesforce...
      // TODO: On success hide spinner and show success message

      const url = "https://www.tfaforms.com/4821149";
      const email = this.email_address;
      const name = this.name;
      const state = this.state;
      const address = this.mailing_address;

      const jsonData = {
        "\"name\"": name,
        "url": email,
        "fullName": state,
        "domain": address
      };
      console.log("json_data", jsonData);
      const response = await axios.post(url, jsonData);
      console.log("response", response);
      this.signed = true
      this.isSigning = false
    },
  }
};
</script>
<style scoped lang="scss">
.petition {
    display: grid;
    grid-gap: 2rem;
    position: relative;

    .accordion {
        position: relative;
        max-height: 20rem;
        overflow: hidden;

        &.isExpanded {
            height: auto;
            max-height: none;
            .expand {
                display: none;
            }
        }

        &:not(.isExpanded) {
            .expand {
                display: grid;
                justify-content: center;
                align-content: center;
                background: linear-gradient(0deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%);
                height: 10rem;
                z-index: 10;
                width: 100%;
                position: absolute;
                top: 10rem;
            }

        }
    }

    form {
        @include grid-v;
    }

    /deep/ .slice-text {
        .other * {
            font-size: 1.5rem;
            text-align: center;
        }

    }
}
</style>
