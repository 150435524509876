<template>
<SliceAction :slice="slice"
             :item="item"></SliceAction>
</template>

<script>
import SliceAction from "@/components/ui/SliceAction.vue";
export default {
  name: "SliceJobApplication",
  props: [ 'item', 'slice' ],
  components: {
    SliceAction
  }
};
</script>
<style scoped lang="scss">
.slice-job-application {}
</style>
