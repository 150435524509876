<template>
<div class="slice"
     :class="[$data.bootstrap.size, $data.bootstrap.format]">
  <b-container :fluid="fluid"
               class="wrapper">
    <slot></slot>
  </b-container>
</div>
</template>

<script>
import breakpoints from "@/scripts/Breakpoints.js"
export default {
  name: "Slice",
  props: {
    'item': Object,
    'fluid': {
      type: Boolean,
      default: false
    }
  },
  mixins: [ breakpoints ],
  components: {},
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
.slice {
    display: grid;
    justify-items: center;
    padding: 0;
    // background: lightyellow;

    > .container.wrapper {
        padding: 0 var(--p2);
        margin: 0;
        // background: color-level(lightyellow,1);
        // border: 2px dashed gold;
    }

    > .container-fluid.wrapper {
        padding: 0;
        // background: red;
    }

    &.mobile > .wrapper {
        padding: 0;
    }

}
</style>
