<template>
<b-card class="post-item"
        :class="[{'pinned': pinned && !simple, 'clean':clean}]"
        v-if="post">

  <template v-if="promo_image">
    <b-img class="visual"
           :alt="title"
           :aria-label="title"
           :src="promo_image" />
  </template>

  <b-link :to="link"
          class=" stretched-link">
    <h1 class="headline"
        v-if="pinned">{{title}}</h1>
    <h1 class="subhead"
        v-else>{{title}}</h1>
  </b-link>

  <div v-if="description"
       class="description">{{ description}}</div>

  <footer v-if="!clean">
    <div class="type">{{type}}</div>
    <div class="timestamp">{{original_time}}</div>
  </footer>

</b-card>
</template>

<script>
import _ from "lodash"
import moment from "moment"
export default {
  name: "PostItem",
  props: {
    'item': Object,
    'clean': {
      type: Boolean,
      default: false
    },
    'simple': {
      type: Boolean,
      default: false
    }
  },

  components: {},
  data() {
    return {
      post: null
    }
  },
  watch: {
    'item': {
      immediate: true,
      handler() {
        if ( this.item && this.item.id ) {
          this.getContent()
        }
      }
    }
  },
  computed: {
    title() {
      return this.post.data.promo_title || this.$prismic.richTextAsPlain( this.post.data.title )
    },
    description() {
      return this.post.data.promo_text || false
    },
    type() {
      if ( this.post.type == "testimonial" ) {
        return _.startCase( "testimony" )
      }
      return _.startCase( this.post.type )
    },
    time() {
      return `${moment(this.post.first_publication_date).fromNow()}`
    },
    releaseDate() {
      return this.post.data.release_date
    },
    original_time() {
      if ( this.releaseDate ) {
        return `${moment(this.post.data.release_date).fromNow()}`
      } else {
        return this.time
      }
    },
    pinned() {
      return ( this.post.data.pinned || this.post.data.promote ) && !this.simple
    },
    promo_image() {
      if ( this.post.data.video ) {
        return this.post.data.video.thumbnail_url
      } else if ( this.post.data.visual ) {
        if ( this.post.data.visual.wide ) {
          return this.post.data.visual.wide.url
        }
        return this.post.data.visual.url
      }
      return false
    },
    readableType() {
      return this.post.type.replace( "_", " " )
    },
    link() {
      if ( this.post.type != 'page' ) {
        return '/' + this.language + '/' + this.post.type + '/' + this.post.uid
      }
      return '/' + this.language + '/' + this.post.uid
    }
  },
  methods: {
    getContent() {
      this.$prismic.client.query(
        this.$prismic.Predicates.at( 'document.id', this.item.id ), {
          lang: this.language
        } ).then( response => {
        if ( response ) {
          this.post = response.results[ 0 ]
        }
      } )
    },
  }
};
</script>
<style scoped lang="scss">
.post-item {
    min-height: 15vw;
    transition: 0.3s;

    &:active,
    &:hover {
        background: theme-color-level("primary", -11.9);
        @include shadow-lg;
    }

    &.pinned {
        .desktop &,
        .tablet & {
            grid-column: span 2;

        }
        @include shadow;

        h4 {
            font-weight: bold;
        }
    }

    .card-body {
        display: grid;
        @include grid-v;
        grid-template-rows: auto auto;
        grid-auto-rows: auto;
        grid-gap: 1rem;
        align-content: space-between;
        align-items: start;
    }

    .headline {
        font-size: var(--fxxl);
        a:hover {
            background: pink;
        }
    }
    .subhead {
        font-size: var(--fxl);
        font-weight: normal;
    }

    .visual {
        // @include swoosh;
        object-fit: cover;
        width: 100%;
        max-height: 10rem;
    }

    .description {
        font-size: 0.8rem;
    }

    .stretched-link {
        &:hover {
            text-decoration: none;
        }
    }

    footer {
        align-self: end;
        .timestamp,
        .type {
            display: grid;
            justify-content: end;
            font-size: 0.6rem;
            @include smallcaps;
        }
    }
}
</style>
