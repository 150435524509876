<template>
<b-navbar class="nav-header"
          toggleable="lg"
          :class="{'scrolled':isScrolled}">

  <b-container class="extra"
               fluid>

    <Icon type="Menu"
          class="d-md-block d-lg-none"
          v-b-toggle.sidebar-1></Icon>
    <Icon type="More"
          class="d-sm-block d-md-none"
          v-b-toggle.sidebar-2></Icon>

    <section class="actions d-none d-md-flex">

      <MoreActions />

    </section>



    <slot></slot>

  </b-container>
</b-navbar>
</template>

<script>
import MoreActions from "@/components/ui/MoreActions"
export default {
  name: "NavHeader",
  components: {
    MoreActions
  },
  data() {
    return {
      isScrolled: false
    }
  },
  created() {
    window.addEventListener( 'scroll', this.wasScrolled );
  },
  destroyed() {
    window.removeEventListener( 'scroll', this.wasScrolled );
  },
  methods: {
    wasScrolled() {
      this.isScrolled = window.scrollY > 0 ? true : false
    }
  }

};
</script>
<style scoped lang="scss">
.nav-header {
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 0;
    display: grid;
    transition: all 0.5s ease-in-out;
    justify-content: stretch;

    &.scrolled:after {
        z-index: -1;
        content: "";
        position: absolute;
        -webkit-box-shadow: 0 0 40px rgba(0,0,0,0.7);
        -moz-box-shadow: 0 0 40px rgba(0,0,0,0.7);
        box-shadow: 0 0 40px rgba(0,0,0,0.7);
        height: 20%;
        left: 10%;
        right: 10%;
        width: 80%;
        bottom: 0;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
    }

    .extra {
        display: grid;
        align-items: center;
        grid-template-columns: auto auto;
        grid-template-areas: "icon actions";
        grid-gap: var(--p2);
        width: 100%;
        background: white;
        align-self: end;
        justify-content: space-between;
        min-height: var(--pxxxl);

        .icon.Menu {
            grid-area: icon;
            height: 1.5rem;
            width: 1.5rem;
            margin: var(--pl);
        }

        .icon.More {
            grid-area: actions;
            height: 1.5rem;
            width: 1.5rem;
            margin: var(--pl);
        }

        .actions {
            grid-area: actions;
        }
    }

}
</style>
