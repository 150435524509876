<template>
<div class="press_release"
     v-if="item">
  <sliceheadline :item="item" />
  <slicetext :text="text" />

</div>
</template>

<script>
export default {
  name: "PressRelease",
  props: [ 'uid' ],
  created() {
    this.getContent()
  },
  data() {
    return {
      item: null
    }
  },
  computed: {
    text() {
      return this.item.data.content
        .concat( {
          type: 'heading6',
          text: '###'
        } )
    }
  },
  methods: {
    getContent( lang ) {
      this.$prismic.client.getByUID( 'press_release', this.uid, {
        lang: lang || this.language,
        fetchLinks: [ 'bio.portrait', 'bio.title', 'bio.job_title', 'bio.qualifications', 'bio.email', 'bio.telephone' ]
      } ).then( response => {
        if ( response ) {
          this.item = response
        }
      } )
    },
  }
};
</script>
<style scoped lang="scss">
.press_release {
    display: grid;
    grid-gap: 2rem;

    /deep/ .slice-text {
        // max-width: 800px;
        .other * {
            font-size: 1.5rem;
            text-align: center;
        }

    }
}
</style>
