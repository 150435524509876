<template>
<button class="super-button"
        role="link"
        :class="[variant,{ 'disabled':disabled}]"
        :disabled="disabled"
        :aria-label="text"
        @click="openLink">
  <div class="content">
    <slot></slot>
  </div>
</button>
</template>
<script>
export default {
  name: 'SuperButton',
  components: {},
  props: {
    variant: {
      type: String,
      default: 'primary'
    },
    text: String,
    to: String,
    link: Object,
    target: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
    click: Function
  },
  computed: {
    isLink() {
      return this.link
    },
    isDocument() {
      return this.isLink && this.link.link_type == 'Document'
    },
    isMedia() {
      return this.isLink && this.link.link_type == 'Media'
    },
    isWeb() {
      return this.isLink && this.link.link_type == 'Web'
    },
    isInternal() {
      return !this.isWeb && !this.isMedia
    },
    preparedLink() {
      if ( this.isDocument ) {
        if ( this.link.type == 'page' ) {
          return "/" + this.language + "/" + this.link.uid
        } else {
          return "/" + this.language + "/" + this.link.type + "/" + this.link.uid
        }
      } else if ( this.isWeb ) {
        return this.link.url
      } else if ( this.isMedia ) {
        return this.link.url
      } else if ( this.to ) {
        return "/" + this.language + "/" + this.to
      } else {
        return false
      }
    },
  },
  methods: {
    openLink() {
      if ( this.disabled ) {
        return
      } else {
        if ( this.click ) {
          this.click()
        } else if ( this.isInternal ) {
          this.$router.push( this.preparedLink )
        } else {
          window.open( this.preparedLink, "_blank" )
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.super-button {
    display: inline-flex;
    justify-self: start;
    width: auto;
    cursor: pointer;
    border-radius: 1rem;
    overflow: hidden;
    border: 0;
    padding: 0;

    &:focus {
        outline: 5px auto -webkit-focus-ring-color;
    }

    .content {
        display: inline-grid;
        grid-gap: 0;
        grid-template-columns: auto 1fr;
        align-items: center;
        align-content: center;
        justify-content: start;
        justify-items: start;
        justify-self: start;

        .icon {
            width: var(--fl);
            height: var(--fl);
            margin: var(--ps);
        }

        p {
            display: grid;
            padding: var(--ps);
            font-size: var(--fl);
            color: inherit;
            margin: 0;
            align-content: center;
            align-self: center;
            line-height: 100%;
            letter-spacing: 0;
        }

    }

    @each $color, $value in $theme-colors {
        &.#{$color} {
            background: $value;

            .content {
                color: color-yiq($value);
                p {
                    background: color-level($value,1);
                }
                .icon {
                    color: inherit;
                }
            }

            &:hover:not(.disabled) {
                @include shadow-lg;
                z-index: 2;
                .icon {
                    transform: scale(1.3);
                }
                p {}
            }
        }
    }

    &.disabled {
        filter:grayscale(100%);
        cursor: default;
    }

}
</style>
