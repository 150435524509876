<template>
<div class="directory"
     v-if="item">

  <sliceheadline :title="item.data.title"
                 :item="item">
    <div class="tools">
      <b-form-group v-if="allTypes.length > 1">
        <b-form-radio-group id="category-filter"
                            v-model="currentType"
                            :options="allTypes"
                            role="link"
                            buttons
                            @change="handleChange"
                            button-variant="outline-primary"
                            size="sm"
                            name="Category filter"></b-form-radio-group>
      </b-form-group>
      <b-form-group>
        <label for="filter"
               class="sr-only">Filter</label>
        <b-form-input v-model="needle"
                      id="filter"
                      placeholder="Filter"
                      @blur="getItems"
                      @keydown.native.enter="getItems" />
      </b-form-group>
      <b-button v-if="needle.length> 0"
                role="link"
                @click="reset"
                variant="outline">
        <Icon type="remove"></Icon>
      </b-button>
    </div>
  </sliceheadline>

  <DirectoryListings v-if="result"
                     :items="sorted" />


</div>
</template>

<script>
import multisort from "multisort"
import DirectoryListings from "@/components/ui/DirectoryListings"
export default {
  name: "Directory",
  props: [ 'uid' ],
  components: {
    DirectoryListings
  },
  mounted() {

    this.getContent()
  },
  data() {
    return {
      item: null,
      result: null,
      directories: [],
      currentType: null,
      needle: ''
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.getContent()
        this.needle = ''
      }
    },
    'item': {
      immediate: true,
      handler() {
        this.currentType = this.uid
      }
    },
  },
  computed: {
    type() {
      return this.item && this.item.data.type ? this.item.data.type.toLowerCase().replace( " ", "_" ) : ''
    },
    allTypes() {
      if ( this.directories.length > 0 ) {

        let list = this.directories.map( a => {
          return {
            text: this.$prismic.richTextAsPlain( a.data.title ),
            value: a.uid,
            type: a.data.type
          }
        } )
        var criteria = [
          function( a ) {
            return a.type != 'All'
          }
        ];

        return multisort( list, criteria )
      }
      return []
    },
    types() {
      if ( this.type == 'all' ) {
        return this.directories.map( a => a.data.type.toLowerCase().replace( " ", "_" ) )
      } else {
        return [ this.type ]
      }
    },
    sorted() {
      let ar = this.result.results.map( a => {
        if ( a.data.release_date ) {
          return a
        } else {
          a.data.release_date = a.first_publication_date
          return a
        }
      } )

      var criteria = [
        '~data.pinned',
        '~data.release_date',
      ];

      return multisort( ar, criteria )
    }
  },
  methods: {
    getContent() {
      this.$prismic.client.getByUID( 'directory', this.uid, {
        lang: this.language,
      } ).then( response => {
        if ( response ) {
          this.item = response

          this.getAllDirectories()
        }
      } );
    },
    getAllDirectories() {
      this.$prismic.client.query( [
        this.$prismic.Predicates.at( 'document.type', 'directory' ),
        this.$prismic.Predicates.at( 'my.directory.grouping', this.item.data.grouping ),
      ], {
        lang: this.language,
      } ).then( response => {
        this.directories = response.results
        this.getItems()
      } );
    },

    getItems() {
      let search = this.needle ? this.$prismic.Predicates.fulltext( 'document', this.needle ) : null
      this.$prismic.client.query( [
        this.$prismic.Predicates.any( 'document.type', this.types ), search

      ], {
        orderings: '[ document.first_publication_date desc]',
        pageSize: 100
      } ).then( ( response ) => {
        this.result = response
      } );
    },
    handleChange( val ) {
      this.$router.push( `/${this.language}/directory/${val}` )
    },
    reset() {
      this.needle = ''
      this.getItems()
    }

  }
};
</script>
<style scoped lang="scss">
.directory {
    display: grid;
    grid-gap: 5rem;

    .tools {
        @include grid-h;
        justify-content: space-between;
        align-items: center;
        align-content: center;

        .form-group {
            margin: 0;
        }

        .mobile & {
            grid-template-rows: 1fr 1fr;
        }

    }

}
</style>
