<template>
<b-container id="app"
             :class="[$data.bootstrap.size, $data.bootstrap.format]"
             fluid>

  <b-sidebar id="sidebar-1"
             no-header
             shadow
             backdrop>
    <Sidebar left
             id="sidebar-1" />
  </b-sidebar>

  <b-sidebar id="sidebar-2"
             no-header
             shadow
             right
             backdrop>
    <Sidebar right
             id="sidebar-2" />
  </b-sidebar>

  <b-row no-gutters
         class="wrapper">
    <b-col v-if="showSidebar"
           lg="2"
           xl="2"
           xxl="2"
           xxxl="2"
           class="side d-none d-lg-block">
      <Sidebar left />
    </b-col>

    <b-col class="main">
      <NavHeader v-if="showNavbar" />
      <router-view />
      <NavFooter />
    </b-col>

  </b-row>

  <!-- <div id="size">
    {{$data.bootstrap.format}}
  </div> -->

</b-container>
</template>
<script>
import Globals from "@/scripts/Globals.js"
import breakpoints from "@/scripts/Breakpoints.js"
import Sidebar from "@/components/ui/Sidebar.vue"
export default {
  name: "App",
  components: {
    Sidebar
  },
  mixins: [ breakpoints, Globals ],
  data() {
    return {
      showSidebar: true,
      showFooter: true,
      showNavbar: true,
    }
  },
  created() {},
  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.showSidebar = this.$route.meta.sidebar || false
        this.showFooter = this.$route.meta.footer || true
        this.showNavbar = this.$route.meta.navbar || false
      }
    }
  },
  // mounted() {
  // this.showSidebar = this.$route.meta.sidebar
  // this.showFooter = this.$route.meta.footer
  // },
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
#app {
    padding: 0;
    position: relative;
    max-width: 1800px;

    .b-sidebar-body {
        background: red;
    }

    .wrapper {
        padding: 0;
        position: relative;

        .main {
            // min-height: 101vh;
            display: grid;
            grid-template-rows: auto 1fr auto;
            padding: 0;
        }

        .side {}
    }
}
</style>
