<template>
<b-link class="person"
        v-if="item"
        :to="`/${language}/bio/${item.uid}`">
  <b-img v-if="item.data.portrait.square.url"
         :src="item.data.portrait.square.url"
         :alt="$prismic.richTextAsPlain( this.item.data.title)" />
  <section>
    <b>{{$prismic.richTextAsPlain( this.item.data.title )}}</b>
    <div class="title">
      {{item.data.job_title}}
    </div>
    <div v-if="item.data.other_title"
         class="other_title">
      {{item.data.other_title}}
    </div>
  </section>

</b-link>
</template>

<script>
export default {
  name: "Person",
  props: [ 'uid' ],
  components: {},
  computed: {},
  data() {
    return {
      item: null
    }
  },
  watch: {
    'uid': {
      immediate: true,
      handler() {
        this.getContent()
      }
    }
  },
  methods: {
    getContent() {
      this.$prismic.client.getByUID( 'bio', this.uid, {
        lang: this.language,
      } ).then( response => {
        this.item = response
      } );
    }
  }
};
</script>
<style scoped lang="scss">
.person {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: 1rem;
    align-content: start;

    img {
        width: 8rem;
        height: 8rem;
        background-color: theme-color-level(primary,-11);
        mask-image: url("~@/assets/Swoosh.png");
        mask-size: 100% 100%;
    }

    section {
        @include grid-v;
        grid-gap: 0;
        align-items: center;
        align-content: center;

        .title {
            font-size: 0.8rem;
        }

        .other_title {
            font-size: 0.7rem;
            font-style: italic;
        }
    }
}
</style>
