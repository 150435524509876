<template>
<Slice>
  <b-container class="slice-people">

    <header>
      <h1 class="main-headline display">
        {{slice.primary.headline}}
      </h1>
    </header>

    <section class="items">
      <Person class="item"
              v-for="person in slice.items"
              :key="person.id"
              :uid="person.person.uid" />
    </section>


  </b-container>
</Slice>
</template>

<script>
export default {
  name: "SliceList",
  props: [ 'slice' ],
};
</script>
<style scoped lang="scss">
.slice-people {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    padding: var(--p5) var(--p6);
    border-radius: 1rem;
    overflow: hidden;

    header {
        display: grid;
        grid-gap: 2rem;
        max-width: 75%;
    }

    .main-headline {
        font-size: var(--fxxxl);
    }

    .items {
        display: grid;
        border-radius: 1rem;
        overflow: hidden;
        width: 100%;
        justify-self: end;
        grid-gap: 5rem;
        grid-template-columns: repeat(auto-fit, 8rem);
    }

    .mobile & {
        background: none;

        header {
            padding: 0 var(--p4);
            max-width: 100%;
        }
        .item {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-auto-rows: auto;
            justify-items: start;
        }
    }

}
</style>
