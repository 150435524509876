import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: 'en-us',
    story: null,
    nav: [],
    header: [],
    search: {},
    newsletterBanner: null,
    newsletterLabel: null,
    newsletterPage: null
  },
  getters: {
    language(state) {
      return state.language;
    },
    story(state) {
      return state.story;
    },
    nav(state) {
      return state.nav;
    },
    header(state) {
      return state.header;
    },
    search(state) {
      return state.search;
    },
    newsletterBanner(state) {
      return state.newsletterBanner;
    },
    newsletterLabel(state) {
      return state.newsletterLabel;
    },
    newsletterPage(state) {
      return state.newsletterPage;
    }
  },
  mutations: {
    setLanguage(state, value) {
      Vue.set(state, "language", value);
    },
    setStory(state, value) {
      Vue.set(state, "story", value);
    },
    setNav(state, value) {
      Vue.set(state, "nav", value);
    },
    setHeader(state, value) {
      Vue.set(state, "header", value);
    },
    setSearch(state, value) {
      Vue.set(state, "search", value);
    },
    setNewsletterBanner(state, value) {
      Vue.set(state, "newsletterBanner", value);
    },
    setNewsletterLabel(state, value) {
      Vue.set(state, "newsletterLabel", value);
    },
    setNewsletterPage(state, value) {
      Vue.set(state, "newsletterPage", value);
    }
  },
  actions: {
    updateLanguage({commit}, value) {
      commit("setLanguage", value);
    },
    updateStory({commit}, value) {
      commit("setStory", value);
    },
    updateNav({commit}, value) {
      commit("setNav", value);
    },
    updateHeader({commit}, value) {
      commit("setHeader", value);
    },
    updateNewsletterBanner({commit}, value) {
      commit("setNewsletterBanner", value);
    },
    updateNewsletterLabel({commit}, value) {
      commit("setNewsletterLabel", value);
    },
    updateNewsletterPage({commit}, value) {
      commit("setNewsletterPage", value);
    },
    updateSearch({commit}, value) {
      commit("setSearch", value);
    }

  }
});
