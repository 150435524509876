<template>
<Slice>
  <div class="slice-headline"
       :class="{'press_release': item.type == 'press_release'}">
    <b-container class="wrapper">


      <PressReleaseInfo v-if="item.type == 'press_release'"
                        :item="item" />
      <h6 v-else-if="showType"
          class="smallcaps">{{type}}</h6>

      <div class="headline">
        <h1>{{ $prismic.richTextAsPlain( title || this.item.data.title )}}</h1>
        <h4>{{ $prismic.richTextAsPlain( this.item.data.subheader ) }}</h4>
        <Swoosh :uid="item.uid"
                animate />
      </div>
      <Attribution :item="item" />

      <slot />
    </b-container>
  </div>
</Slice>
</template>

<script>
import Attribution from "@/components/ui/Attribution"
import PressReleaseInfo from "@/components/ui/PressReleaseInfo"
import Swoosh from "@/components/ui/Swoosh"
export default {
  name: "SliceHeadline",
  props: [ 'item', 'title' ],
  components: {
    Attribution,
    PressReleaseInfo,
    Swoosh
  },
  computed: {
    showType() {
      let excluded = [ 'home', 'page', 'directory', 'bio', 'press_release', 'newsletters', 'search' ]
      return !excluded.includes( this.item.type )
    },
    type() {
      return this.item.type.replace( "_", " " )
    }
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.slice-headline {
    padding: var(--p6) 0 var(--p2);

    .wrapper {
        @include grid-v;
        .attribution {
            // margin-left: var(--p3);
        }
    }

    .headline {
        .desktop & h1 {
            max-width: 80%;
        }

        .mobile & {
            h1 {
                line-height: 100%;
            }

            & .swoosh {
                width: 100%;
            }
        }

        .tablet & .swoosh {
            width: 70%;
        }

        .desktop & .swoosh {
            width: 50%;
        }
    }

}
</style>
