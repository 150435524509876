<template>
  <footer class="nav-footer">
    <Slice>
      <div class="elements">
        <div class="rows">
          <SliceSmallAction v-if="newsletterBanner && $route.name!='Newsletters'" :slice="newsletterBanner.body[0]" />
          <div class="columns">
            <NavItem
              v-for="link in nav"
              name="navitem"
              footer
              :key="link.uid"
              :item="link"
            />

            <section class="rest">
              <b-link :href="`/${language}/home`" name="Home" aria-label="Home">
                <Icon type="Logo"></Icon>
              </b-link>

              <div class="social-media-icons">
                <b-link
                  href="https://www.facebook.com/HungerFreeUSA"
                  target="_blank"
                  name="Facebook"
                  aria-label="Facebook"
                >
                  <Icon type="Facebook"></Icon>
                </b-link>
                <b-link
                  href="https://twitter.com/HungerFreeUSA"
                  target="_blank"
                  name="Twitter"
                  aria-label="Twitter"
                >
                  <Icon type="Twitter"></Icon>
                </b-link>
                <b-link
                  href="https://www.youtube.com/channel/UClBrqc1YhVfKcW89aQmQFEw"
                  target="_blank"
                  name="YouTube"
                  aria-label="YouTube"
                >
                  <Icon type="Youtube"></Icon>
                </b-link>
                <b-link
                  href="https://www.instagram.com/hunger_free_america/"
                  target="_blank"
                  name="Instagram"
                  aria-label="Instagram"
                >
                  <Icon type="Instagram"></Icon>
                </b-link>
              </div>

              <div class="contact-info">
                <p>50 Broad St. Suite 1504</p>
                <p>New York, NY 10004</p>
                <p>
                  <a href="mailto:info@hungerfreeamerica.org"
                    >info@hungerfreeamerica.org</a
                  >
                </p>
                <p>Tel <a href="tel://2128250028">(212) 825-0028</a></p>
                <p>Fax <a href="tel://2128250027">(212) 825-0027</a></p>
              </div>

              <div class="legal smallcaps">
                <p>
                  <a :href="`/${language}/privacy-policy`">Privacy Policy</a>
                </p>
                <p>© {{ year }} HUNGER FREE AMERICA</p>
              </div>
            </section>

            <section class="rest">
              <a
                href="https://www.guidestar.org/profile/13-3471350"
                target="_blank"
                ><img
                  src="https://widgets.guidestar.org/gximage2?o=6919510&l=v4"
              /></a>
            </section>
          </div>
        </div>
        <footer></footer>
      </div>
    </Slice>
  </footer>
</template>

<script>
import SliceSmallAction from "./SliceSmallAction.vue";
export default {
  components: { SliceSmallAction },
  name: "NavFooter",
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>
<style scoped lang="scss">
footer.nav-footer {
  margin-top: var(--p6);
  background: white;

  .elements {
    padding-top: 6rem;
    padding-bottom: 6rem;
    display: grid;
  }

  .columns {
    width: 100%;
    display: grid;
    grid-gap: var(--p4) var(--p1);
    grid-row-gap: 0.2rem;
    justify-content: stretch;
    grid-auto-flow: row dense;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    grid-template-rows: auto;
    grid-auto-rows: auto;

    section.rest {
      @include grid-v;
      grid-gap: 0.5rem;
      align-items: start;
      align-content: start;
      grid-gap: 2rem;

      .Logo {
        width: 5rem;
      }

      > div {
        margin-left: var(--p2);
      }

      .legal {
        font-size: 0.5rem;
      }

      .social-media-icons {
        @include grid-h;
        grid-gap: 0;

        a {
          color: $primary;

          &:hover {
            color: $danger;
          }

          .icon {
            height: auto;
            width: 2rem;
          }
        }
      }

      .contact-info {
        p {
          font-size: 0.8rem;
          margin: 0;
        }
      }
    }
  }
  .mobile &,
  .tablet & {
    background: theme-color-level(primary, -11.9);
    .columns {
      grid-gap: 2rem 1rem;
    }

    section.rest {
      grid-column: span 2;
    }

    .slice {
      padding: var(--p6);
    }
    .elements {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}
</style>
