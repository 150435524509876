<template>
<Slice>
  <b-container class="slice-list">
    <header>
      <h2 class="main-headline display">
        {{slice.primary.headline}}

      </h2>
      <div v-if="hasMainDescription"
           class="main-description">
        <prismic-rich-text :field="slice.primary.description" />
      </div>
    </header>

    <section class="items"
             :class="slice.primary.show_as_grid ? 'grid' : 'list'">
      <div class="item"
           v-for="item in slice.items"
           :key="item.id"
           :to="item.link.url">
        <template>
           <b-img :alt="item.optional_image.alt"
                  :src="item.optional_image.url"
                  class="visual" />
          <div class="text">
            <div><b>{{item.label}}</b></div>
            <span>{{item.description}}</span>

            <template v-if="item.link.url">
              <b-button variant="primary"
                        class="outline"
                        :aria-label="item.link.label"
                        :href="item.link.url">{{item.link.label || 'Link'}}</b-button>
            </template>
            <template v-else-if="item.link.uid">
              <b-button variant="primary"
                        class="outline"
                        :aria-label="item.link.label"
                        :to="'/'+language+'/'+item.link.uid">{{item.link.label || 'Link'}}</b-button>
            </template>
          </div>
        </template>

      </div>
    </section>


  </b-container>
</Slice>
</template>

<script>
export default {
  name: "SliceList",
  props: [ 'item', 'slice' ],
  components: {},
  computed: {
    hasMainDescription() {
      console.log("slice:", this.slice)
      return this.slice.primary.description && this.slice.primary.description.length > 0 && this.slice.primary.description[ 0 ].text != "" ? true : false
    },
    edges() {
      if ( this.slice.primary.edges === true ) {
        return 'clean'
      }
      return 'distressed'
    }
  },
  methods: {

  }
};
</script>
<style scoped lang="scss">
.slice-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    padding: var(--p5) var(--p6);
    overflow: hidden;

    header {
        display: grid;
        grid-gap: 1rem;
    }

    .items {
        grid-template-columns: 1fr 2fr;
        overflow: hidden;
        align-items: start;
        align-content: start;
        grid-gap: 2rem;

        .item {
            display: grid;
            align-items: center;
            align-content: center;
            padding: var(--p2) var(--p5);
            grid-gap: var(--p1);

            .visual {
                width: 50%;
                height: 50%;
                object-fit: cover;
                //@include swoosh;
                max-height: 10rem;
            }
            .text {
                display: grid;
                align-items: center;
                align-content: center;
            }

        }

        &.grid {
            grid-gap: 1rem;
            grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));

            .item {
                grid-template-rows: 1fr auto;
                justify-content: start;
                justify-items: start;
            }
        }

        &.list {
            .item {
                grid-template-columns: 1fr 2fr;
                grid-column-gap: 2rem;
                background: theme-color-level(primary,-12);
                border-top: 1px solid theme-color-level(primary,-11);

                .visual {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    @include swoosh;
                    max-height: 10rem;
                }
                .text {
                    grid-row-gap: 1rem;
                }
                .outline {
                    display: inline-block;
                    justify-self: start;
                }
                &:first-child {
                    padding-top: var(--p3);
                    border-top: none;
                }
                &:last-child {
                    padding-bottom: var(--p3);
                }
            }
        }

    }

    .mobile & {
        background: none;

        header {
            padding: 0 var(--p4);
            max-width: 100%;
        }
        .item {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-auto-rows: auto;
            justify-items: start;
        }
    }

    .tablet & {}

    .desktop & {
        .items {
            // width: 80%;
        }
    }

}
</style>
