import Vue from 'vue'
import App from './App.vue'
import router from "@/router"
import store from "@/store"

Vue.config.productionTip = false
Vue.config.devtools = false

// ######################################
// Bootstrap
import "@/styles/global.scss";
import {BootstrapVue} from "bootstrap-vue";
Vue.use(BootstrapVue, {
  breakpoints: ["xs", "sm", "md", "lg", "xl", "xxl", "xxxl"],
  BButton: {
    size: "sm",
    variant: "primary"
  }
});

// ######################################
// Markdown parser for SliceHeader
import VueSimpleMarkdown from "vue-simple-markdown";
Vue.use(VueSimpleMarkdown);


// ######################################
// Prismic
import PrismicVue from "@prismicio/vue";
import linkResolver from '@/prismic/linkResolver';
Vue.use(PrismicVue, {
  endpoint: "https://hfa-website.prismic.io/api/v2",
    linkResolver: linkResolver
});

// ######################################
// Vuex Store global getters
import {mapGetters} from "vuex";
Vue.mixin({
  computed: {
    ...mapGetters([
      "language",
      "story",
      "nav",
      "header",
      "search",
      "newsletterBanner",
      "newsletterLabel",
      "newsletterPage"
    ])
  }
});

// ######################################
// Global components
import Icon from "@/components/ui/Icon.vue";
import Person from "@/components/ui/Person.vue";
import SuperButton from "@/components/ui/SuperButton.vue";
import Slice from "@/components/ui/Slice.vue";
import SliceHeader from "@/components/ui/SliceHeader.vue";
import SliceHeadline from "@/components/ui/SliceHeadline.vue";
import SlicePeople from "@/components/ui/SlicePeople.vue";
import SliceJobApplication from "@/components/ui/SliceJobApplication.vue";
import SliceVisual from "@/components/ui/SliceVisual.vue";
import SliceText from "@/components/ui/SliceText.vue";
import SliceAction from "@/components/ui/SliceAction.vue";
import SliceSmallAction from "@/components/ui/SliceSmallAction.vue";
import SliceList from "@/components/ui/SliceList.vue";
import SliceListVisuals from "@/components/ui/SliceListVisuals.vue";
import SlicePetition from "@/components/ui/SlicePetition.vue";
import SliceTable from "@/components/ui/SliceTable.vue";
import SliceQuote from "@/components/ui/SliceQuote.vue";
import SliceEmbed from "@/components/ui/SliceEmbed.vue";
import SliceFeatured from "@/components/ui/SliceFeatured.vue";
import NavItem from "@/components/ui/NavItem.vue";
import NavHeader from "@/components/ui/NavHeader.vue";
import NavFooter from "@/components/ui/NavFooter.vue";
// import NotFound from "@/views/NotFound.vue";
Vue.component("Icon", Icon);
Vue.component("Person", Person);
Vue.component("SuperButton", SuperButton);
Vue.component("Slice", Slice);
Vue.component("sliceheader", SliceHeader);
Vue.component("sliceheadline", SliceHeadline);
Vue.component("slicepeople", SlicePeople);
Vue.component("slicevisual", SliceVisual);
Vue.component("slicetext", SliceText);
Vue.component("sliceaction", SliceAction);
Vue.component("slicesmallaction", SliceSmallAction);
Vue.component("slicejob_application", SliceJobApplication);
Vue.component("slicelist", SliceList);
Vue.component("slicelist_with_visuals", SliceListVisuals);
Vue.component("slicepetition", SlicePetition);
Vue.component("slicecsv_table", SliceTable);
Vue.component("slicequote", SliceQuote);
Vue.component("sliceembed", SliceEmbed);
Vue.component("slicefeatured", SliceFeatured);
Vue.component("NavItem", NavItem);
Vue.component("NavHeader", NavHeader);
Vue.component("NavFooter", NavFooter);
// Vue.component("NotFound", NotFound);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
