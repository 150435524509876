import store from "@/store"

export default function(doc) {
  let lang = store.getters.language
  // console.log(store.getters.language)
  // Return the path depending on Prismic Document's type
  // If it is a Single Custom Type with the API ID of "home"
  if (doc.type === "home") {
    return "/"+lang+"/";
  }else if (doc.type === "page") {
    return "/"+lang+"/" + doc.uid;
  }else if (doc.type === "newsletters") {
    return "/"+lang+"/newsletters";
  }else if(doc.type){
    return "/"+lang+"/"+doc.type+"/" + doc.uid;
  }else{
    // Default to the root
    return "/";
  }


}
