<template>
<div class="newsletter-item">
  <div class="left">
    <h5>{{$prismic.richTextAsPlain( this.item.data.title )}}</h5>
    <p>{{item.data.description}}</p>
  </div>
  <div class="delivery_options">
    <b-form-checkbox :id="'email_delivery'+this._uid"
                     :name="'email_delivery'+this._uid"
                     :disabled="!item.data.email_delivery"
                     v-model="email_delivery">
      Email
    </b-form-checkbox>
    <b-form-checkbox :id="'text_delivery'+this._uid"
                     :name="'text_delivery'+this._uid"
                     :disabled="!item.data.text_delivery"
                     v-model="text_delivery">
      Text
    </b-form-checkbox>
    <b-form-checkbox :id="'mail_delivery'+this._uid"
                     :name="'mail_delivery'+this._uid"
                     :disabled="!item.data.mail_delivery"
                     v-model="mail_delivery">
      Mail
    </b-form-checkbox>
  </div>
</div>
</template>

<script>
export default {
  name: "NewsletterItem",
  props: {
    'item': Object,
  },
  data() {
    return {
      email_delivery: false,
      text_delivery: false,
      mail_delivery: false,
    }
  },
  watch: {
    'opted': {
      immediate: true,
      handler( newValue ) {
        this.$emit( 'opted', newValue )
      }
    }
  },
  components: {},
  computed: {
    opted() {
      return {
        id: this._uid,
        opted: this.email_delivery || this.text_delivery || this.mail_delivery,
        email_delivery: this.email_delivery,
        text_delivery: this.text_delivery,
        mail_delivery: this.mail_delivery
      }
    }
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.newsletter-item {
    display: grid;
    justify-self: start;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    p {
        font-size: 0.8rem;
    }
    .delivery_options {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}
</style>
