<template>
<div class="bio"
     v-if="item">

  <sliceheadline tone="normal"
                 :title="item.data.title"
                 :item="item" />

  <b-container class="info">
    <div v-if="item.data.portrait.square.url">
      <b-img :src="item.data.portrait.square.url"
             :alt="$prismic.richTextAsPlain( this.item.data.title)" />
    </div>
    <div class="details">
      <div v-if="job_title">
        <h1 class="job-title">{{job_title}}</h1>
      </div>
      <div v-if="other_title">{{other_title}}</div>
      <div v-if="email"><a :href="`mailto:${email}`">{{email}}</a></div>
      <div v-if="telephone">
        <a :href="`tel:${telephone}`">{{telephone}}</a>
      </div>
      <div v-if="pronouns">Preferred pronouns: {{pronouns}}</div>
    </div>
  </b-container>

  <slicetext :text="text" />

  <b-container class="fave"
               v-if="fave_food">

    <div class="smallcaps">Favorite food</div>
    <h4 class="food">{{fave_food.favorite_food}}</h4>

    <div class="description">
      <prismic-rich-text :field="fave_food.favorite_food_description" />
    </div>

  </b-container>

</div>
</template>

<script>
export default {
  name: "Bio",
  props: [ 'uid' ],
  created() {
    this.getContent()
  },
  data() {
    return {
      item: null
    }
  },
  computed: {
    text() {
      return this.item.data.full_bio
    },
    job_title() {
      return this.item.data.job_title
    },
    telephone() {
      return this.item.data.telephone
    },
    email() {
      return this.item.data.email
    },
    other_title() {
      return this.item.data.other_title
    },
    fave_food() {
      let fave = this.item.data.body.find( a => a.slice_type == 'favorite_food' )
      if ( fave ) {
        return fave.primary
      }
      return false
    },
    pronouns() {
      let fave = this.item.data.body.find( a => a.slice_type == 'pronoun' )
      if ( fave ) {
        return fave.primary.preferred_pronoun
      }
      return false
    }
  },
  methods: {
    getContent( lang ) {
      this.$prismic.client.getByUID( 'bio', this.uid, {
        lang: lang || this.language
      } ).then( response => {
        if ( response ) {
          this.item = response
        }
      } )
    },
  }
};
</script>
<style scoped lang="scss">
.bio {
    display: grid;
    grid-gap: 3rem;

    .info {
        @include grid-h;
        grid-gap: 2rem;
        grid-template-columns: 1fr 3fr;

        img {
            margin: 0 auto;
            width: 14rem;
            height: 14rem;
            background-color: theme-color-level(primary,-11);
            mask-image: url("~@/assets/Swoosh.png");
            mask-size: 100% 100%;
        }

        .details {
            @include grid-v;
            align-content: center;
            grid-gap: 0;
            > * {
                max-width: 24rem;
            }

            .job-title {
                font-size: var(--fxxxl);
            }
        }
    }

    .fave {
        padding: 0 var(--p7);

        .smallcaps {
            font-size: var(--fxs);
        }

        .description {
            // font-size: 0.8rem;
            font-style: italic;
        }
    }

}
</style>
