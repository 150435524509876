<template>
<section name="other languages"
         class="language">
  <b-link v-for="lang in languages"
          :key="lang.id"
          variant="clear"
          size="sm"
          @click="updateLanguage(lang.code); expanded =  false;"
          class="lang"
          :class="{'selected': lang.code == language }">
    <span>{{lang.localizedName}}</span>
  </b-link>
  <b-link variant="clear"
          v-if="!expanded && availableLanguages.length > limit"
          @click="expandLanguages">More...</b-link>
</section>
</template>
<script>
import multisort from "multisort"
import Languages from "@/scripts/Languages.js"
export default {
  name: 'Language',
  mounted() {
    this.getAvailableLanguages()
  },
  data() {
    return {
      availableLanguages: [],
      limit: 3,
      expanded: false,
    }
  },
  computed: {
    languages() {
      let all = this.availableLanguages
      if ( this.expanded ) {
        return all
      } else {
        if ( all.length > this.limit + 1 ) {
          return all.slice( 0, this.limit )
        }
        return all
      }
    }
  },
  methods: {
    getAvailableLanguages() {
      this.expanded = false
      console.log( "getAvailableLanguages" )
      let lang = this.language || 'en-us'
      this.$prismic.client.getSingle(
          'navigation', {
            lang: lang
          } )
        .then( ( document ) => {
          let list = document.alternate_languages
            .map( a => Languages.locales.find( b => a.lang == b.code ) )

          this.availableLanguages = multisort( list, [ '~default' ] )
        } )
    },
    expandLanguages() {
      this.expanded = !this.expanded
    },
    updateLanguage( code ) {
      this.$store.dispatch( "updateLanguage", code )
      this.$router.push( "/" + code + "/home" )
    },
  }
}
</script>
<style scoped lang="scss">
.language {
    @include grid-v;
    padding: var(--p2);

    .lang {
        transition: all 0.2s;
        @include grid-h;

        &.selected {
            display: none;
        }

        &:active,
        &:focus,
        &:hover {
            color: $primary;
            opacity: 1;
        }

    }
    .isNotDesktop & {
        padding: var(--p6) var(--p5);
        font-size: var(--fm);
    }
}
</style>
