<template>
<b-container class="directory-listings">
  <slot></slot>
  <section class="grid"
           :class="{'dense':dense}"
           v-if="items && items.length > 0">
    <PostItem v-for="(item, i) in items"
              :key="i"
              :clean="clean"
              :simple="simple"
              :item="item" />

  </section>

  <b-alert show
           variant="warning"
           v-else-if="items.length == 0">
    0 results found
  </b-alert>

  <b-spinner v-else />
</b-container>
</template>
<script>
import PostItem from "@/components/ui/PostItem"
export default {
  name: 'DirectoryListings',
  components: {
    PostItem
  },
  props: {
    'items': Array,
    'clean': {
      type: Boolean,
      default: false
    },
    'dense': {
      type: Boolean,
      default: false
    },
    'simple': {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {}
  },
  computed: {

  }
}
</script>
<style scoped lang="scss">
.directory-listings {
    .grid {
        display: grid;
        grid-gap: 2rem;

        .mobile & {
            grid-template-columns: 1fr;
        }

        .desktop &,
        .tablet & {
            grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
        }

        &.dense {
            grid-auto-flow: row dense;
        }
    }
}
</style>
