<template>
<Icon class="swoosh"
      type="MarkerLine"
      :key="uid" />
</template>
<script>
export default {
  name: 'Swoosh',
  components: {},
  props: {
    uid: String,
    animate: {
      type: Boolean,
      default: false
    },
  },
  computed: {}
}
</script>
<style scoped lang="scss">
.swoosh {
    color: $danger;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    animation: draw 0.8s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
}

@keyframes draw {
    0% {
        clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}
</style>
