<template>
<div class="page-not-found">

  <Slice>
    <h1>Page not found</h1>

    <SuperButton to="search">
      <p>Search instead</p>
      <Icon type="arrow"></Icon>
    </SuperButton>
  </Slice>

</div>
</template>

<script>
import Slice from "@/components/ui/Slice"
export default {
  name: "PageNotFound",
  components: {
    Slice
  },
  created() {
    console.log( "Showing 404" )
  }
};
</script>
<style scoped lang="scss">
.page-not-found {
    display: grid;
    grid-gap: 2rem;

    .slice {
        align-items: center;
        justify-content: center;
        ::v-deep .wrapper.container {
            align-items: center;
            justify-content: center;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-gap: var(--pxxl);

            .mobile & {
                grid-template-columns: 1fr;
                align-content: center;

                min-height: 20vh;
            }
        }

        .icon {
            height: var(--pxxl);
            width: var(--pxxl);
        }
    }
}
</style>
