<template>
<Slice>
  <b-container class="slice-quote">

    <b-container class="wrapper quote display">
      <blockquote>
        <span class="graphic">&ldquo;</span>
        <prismic-rich-text class="rich"
                           :field="slice.primary.quote" />
        <footer>
          <h6>
            <b-link v-if="slice.primary.attribution_link.url"
                    :href="slice.primary.attribution_link.url">&mdash; {{slice.primary.attribution}}</b-link>
            <span v-else-if="slice.primary.attribution">&mdash; {{slice.primary.attribution}}</span>
          </h6>
        </footer>
      </blockquote>


    </b-container>

  </b-container>
</Slice>
</template>

<script>
export default {
  name: "SliceQuote",
  props: [ 'item', 'slice' ],
  components: {},
  computed: {

  },
  methods: {

  }
};
</script>
<style scoped lang="scss">
.slice-quote {

    .quote {
        margin-left: var(--p2);
        padding: var(--p2) 0;

        blockquote {
            position: relative;

            .rich {
                margin-left: 3rem;
            }

            .graphic {
                color: theme-color-level(primary, -10);
                position: absolute;
                top: -2rem;
                font-size: 5rem;
                font-family: sans-serif;
            }

            footer {
                @include smallcaps;
                margin-left: 3rem;
            }
        }

    }

}
</style>
