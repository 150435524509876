import Vue from "vue";
import VueRouter from "vue-router";
import VueGtag from "vue-gtag";
import VueFacebookPixel from 'vue-facebook-pixel';
import Page from "@/views/Page.vue";
import Home from "@/views/Home.vue";
import Search from "@/views/Search.vue";
import Directory from "@/views/Directory.vue";
import Bio from "@/views/Bio.vue";
import PressRelease from "@/views/PressRelease.vue";
import Video from "@/views/Video.vue";
import Petition from "@/views/Petition.vue";
import Newsletters from "@/views/Newsletters.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import Preview from "@/views/Preview.vue";

import store from '@/store'
import {locales} from "@/scripts/Languages.js"
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'root',
    beforeEnter(to, from, next) {
      next(store.state.language)
    }
  },
  {
    path: '/:lang/preview',
    name: 'preview',
    component: Preview,
    props: true,
  },
  {
    path: "/not-found",
    component: PageNotFound,
    meta: {sidebar: true, navbar: true, footer: true}
  },
  {
    path: "/:lang/home",
    name: "home",
    alias: "/:lang",
    component: Home,
    props: true,
    meta: {uid:"home", sidebar: true, navbar: true, footer: true}
  },
  {
    path: "/:lang/search/:needle?",
    name: "Search",
    component: Search,
    props: true,
    meta: {uid:"search", sidebar: true, navbar: true, footer: false}
  },
  {
    path: "/:lang/bio/:uid",
    name: "Bio",
    component: Bio,
    props: true,
    meta: {sidebar: true, navbar: true, footer: true}
  },
  {
    path: "/:lang/directory/:uid",
    name: "Directory",
    component: Directory,
    props: true,
    meta: { sidebar: true, navbar: true, footer: true}
  },
  {
    path: "/:lang/newsletters/:uid?",
    name: "Newsletters",
    component: Newsletters,
    props: true,
    meta: {uid: "newsletters", sidebar: true, navbar: true, footer: true}
  },
  {
    path: "/:lang/page/:uid",
    name: "Page2",
    component: Page,
    props: true,
    meta: {sidebar: true, navbar: true, footer: true}
  },
  {
    path: "/:lang/press_release/:uid",
    name: "Press Release",
    component: PressRelease,
    props: true,
    meta: {sidebar: true, navbar: true, footer: true}
  },
  {
    path: "/:lang/video/:uid",
    name: "Video",
    component: Video,
    props: true,
    meta: {sidebar: true, navbar: true, footer: true}
  },
  {
    path: "/:lang/petition/:uid",
    name: "Petition",
    component: Petition,
    props: true,
    meta: {sidebar: true, navbar: true, footer: true}
  },
  {
    path: "/:lang/:type/:uid",
    name: "CustomPage",
    component: Page,
    props: true,
    meta: {sidebar: true, navbar: true, footer: true}
  },
  {
    path: "/:lang/:uid",
    name: "SimplePage",
    component: Page,
    props: true,
    meta: {sidebar: true, navbar: true, footer: true}
  },
  {
    path: "/en-us/volunteer",
    name: "Volunteer",
    beforeEnter(){window.location.replace("https://volunteer.hungerfreeamerica.org")}
  },
  {
    path: "/en-us/senior-volunteer",
    name: "Volunteer",
    beforeEnter(){window.location.replace("https://volunteer.hungerfreeamerica.org")}
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// ######################################
// FB Pixel
Vue.use(VueFacebookPixel);
Vue.analytics.fbq.init('1410562982610357', {
  em: 'user@example.com'
});

// ######################################
// Google Analytics
Vue.use(
  VueGtag,
  {
    config: {id: "UA-7682665-3"}
  },
  router
);

router.beforeEach((to, from, next) => {
  let lang = to.params.lang
  let isValidLang = locales.map(a => a.code).includes(lang)
  let isNotFound = to.path == "/not-found"
  let isRoot = to.path == "/"
  // console.log(to, from, next)
  // console.log(locales , isValidLang, isNotFound)
    if (store.state.locale !== lang && isValidLang) {
      // console.warn("New valid language -> redirecting")
      store.dispatch('updateLanguage', lang)
    }else if( to.path == "/preview"){
      return next("/en-us" + to.fullPath)
    }else if(!isValidLang && !isNotFound &&  !isRoot){
      // console.warn("Not a valid language -> sending to 404")
      return next("/not-found")
    }else if(isRoot){
      return next("/en-us/")
    }
    return next()
})

router.afterEach(to => {
  Vue.nextTick(() => {
    if (to.meta.title) {
      document.title = "HFA — " + to.meta.title;
    } else {
      document.title = "HFA";
    }
    Vue.analytics.fbq.event('ViewContent', {
      content_name: window.location.href
    })
    window.scroll(0, 0);
  });
});

export default router;
