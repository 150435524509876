<template>
  <nav class="sidebar" :class="{ isNotDesktop: isNotDesktop }">
    <template v-if="left">
      <b-link
        class="brand clean"
        aria-label="Home"
        :to="'/' + language + '/home'"
      >
        <Icon type="logo" />
      </b-link>

      <section class="info">
        <div class="list">
          <NavItem
            v-for="link in nav"
            name="navitem"
            :key="link.uid"
            :item="link"
          />
          <NavItem
            v-if="showNewsletterLink()"
            name="navitem"
            highlight
            :item="newsletterItem()"
          />
        </div>
      </section>

      <footer>
        <Language :key="language" />
      </footer>
    </template>

    <template v-if="right">
      <MoreActions />
    </template>
  </nav>
</template>

<script>
import breakpoints from "@/scripts/Breakpoints.js";
import Language from "@/components/ui/Language";
import MoreActions from "@/components/ui/MoreActions";
export default {
  name: "Sidebar",
  props: {
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [breakpoints],
  components: {
    Language,
    MoreActions,
  },
  methods: {
    newsletterItem() {
      let item = {
        primary: {
          text: this.newsletterLabel[0].text,
          link: this.newsletterPage,
        },
      };
      return item;
    },
    newsletterLinkExists() {
      return this.newsletterPage && this.newsletterLabel;
    },
    showNewsletterLink() {
      return this.newsletterLinkExists() && this.$route.name != "Newsletters"
    }
  },
};
</script>
<style scoped lang="scss">
.sidebar {
  position: sticky;
  top: 0;
  padding: 0;
  margin: 0;
  height: 100vh;
  padding: 10vh 0 var(--p2);
  width: 100%;
  background: white;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-gap: 2rem;
  align-items: start;
  align-content: start;
  z-index: 9000;
  max-width: 12rem;
  margin: 0 auto;
  padding-top: var(--p6);
  overflow-y: auto;

  .mobile &,
  .tablet & {
    max-width: none;
  }

  .brand {
    padding: 1rem;
    .icon {
      height: 4rem;
    }
  }

  section {
    @include grid-v;
    align-items: start;
    align-content: start;

    .list {
      @include grid-v;
      grid-row-gap: var(--p1);
    }

    a {
      color: $primary;
    }
  }

  footer {
    font-size: 0.8rem;
  }
}
</style>
