<template>
<Slice>
  <b-container fluid
               class="slice-text">
    <b-container class="wrapper">
      <TextView :text="content" />
    </b-container>
  </b-container>
</Slice>
</template>

<script>
import TextView from "@/components/ui/TextView"
export default {
  name: "SliceText",
  props: [ 'item', 'slice', 'text' ],
  components: {
    TextView
  },
  computed: {
    content() {
      if ( this.text ) {
        return this.text
      } else if ( this.slice ) {
        return this.slice.primary.text
      } else {
        return this.item
      }
    }
  }
};
</script>
<style scoped lang="scss">
.slice-text {
    .wrapper {
        display: grid;
        grid-row-gap: var(--p3);
    }
}
</style>
