<template>
<div class="more-actions">

  <b-button variant="danger"
            href="https://findfood.hungerfreeamerica.org"
            target="_blank">{{findFoodButton}}</b-button>
  <b-button variant="outline-danger"
            :href="`/${language}/nyc`"
            target="_blank">{{nycButton}}</b-button>
  <b-button variant="outline-danger"
            href="https://www.classy.org/give/478193/#!/donation/checkout"
            target="_blank">{{donateButton}}</b-button>
  <b-button variant="outline-danger"
            href="https://volunteer.hungerfreeamerica.org"
            target="_blank">{{volunteerButton}}</b-button>
  <b-button variant="outline-primary"
            :to="`/${language}/search`">
    <Icon type="Search" />
  </b-button>

</div>
</template>

<script>
export default {
  name: "MoreActions",
  computed: {
    donateButton() {
      return this.header && this.header.donate_button ? this.header.donate_button : 'Donate'
    },
    volunteerButton() {
      return this.header && this.header.volunteer_button ? this.header.volunteer_button : 'Volunteer'
    },
    findFoodButton() {
      return this.header && this.header.donate_button ? this.header.donate_button : 'Find food'
    },
    nycButton() {
      return this.header && this.header.nyc_button ? this.header.nyc_button : 'HFA NYC'
    },
  },
};
</script>
<style scoped lang="scss">
.more-actions {
    @include grid-h;

    .sidebar & {
        padding: 1rem;
        @include grid-v;
        justify-content: stretch;
    }
}
</style>
