<template>
<Slice>
  <div class="slice-embed"
       ref="embed"
       :style="styles">
    <prismic-embed ref="obj"
                   :field="url || slice.primary.url" />
  </div>
</Slice>
</template>

<script>
export default {
  name: "SliceEmbed",
  props: [ 'item', 'slice', 'url' ],
  components: {},
  data() {
    return {
      height: 0,
      width: 0,
      type: null,
    }
  },
  mounted() {
    this.getWidth()
  },
  created() {
    window.addEventListener( "resize", this.getWidth );
  },
  destroyed() {
    window.removeEventListener( "resize", this.getWidth );
  },
  computed: {
    styles() {
      if ( this.type == 'video' ) {
        return 'height:' + this.adjustedHeightForVideo + 'px'
      } else {
        return {}
      }
    },
    adjustedHeightForVideo() {
      return this.width > 0 ? this.width * 0.56 : 100;
    },
  },
  methods: {
    getWidth() {
      this.width = this.$refs.embed.clientWidth
      this.type = this.$refs.obj && this.$refs.obj.dataset && this.$refs.obj.dataset.oembedType ? this.$refs.obj.dataset.oembedType : null
    }
  }
};
</script>
<style scoped lang="scss">
.slice {
    .slice-embed {
        display: grid;
        border-radius: 0.5rem;
        overflow: hidden;
        @include shadow-lg;
        width: 100%;
        height: 100%;

        // [data-oembed-provider] {
        //     padding: 2rem;
        // }

        /deep/ [data-type="article"] a,
        /deep/ [data-type="website"] a {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto;
            grid-template-areas: "title image" "description image";
            align-items: center;
            align-content: center;
            grid-column-gap: 4rem;

            h1 {
                grid-area: title;
                font-size: 1.5rem;
                align-self: end;
            }
            p {
                align-self: start;
                grid-area: description;
            }
            img {
                // max-height: 20rem;
                width: 100%;
                grid-area: image;
            }

            .mobile & {
                grid-template-columns: 1fr;
                grid-template-rows: auto;
                grid-auto-rows: auto;
                grid-template-areas: "title" "image" "description";
            }
        }

        // RICH
        /deep/ [data-oembed-type="rich"],
        /deep/ [data-oembed-type="link"] {
            display: grid;
            padding: var(--p5);
            align-content: center;
            justify-content: center;

            > blockquote {
                display: none;
            }
        }

        // VIDEO
        [data-oembed-type="video"] {

            /deep/ iframe {
                display: block;
                width: 100%;
                height: 100%;
            }
        }

        .mobile & {
            border-radius: 0;
        }
    }
}
</style>
