<template>
<div class="search"
     v-if="item">

  <sliceheadline :title="item.data.title"
                 :item="item">
    <div class="tools">
      <b-input v-model="needle"
               :placeholder="item.data.placeholder"
               @blur="updateNeedle"
               @keydown.native.enter="updateNeedle"
               type="text"></b-input>
      <b-button v-if="needle.length > 0"
                @click="updateNeedle"
                size="lg">
        <Icon type="search"></Icon>
      </b-button>
      <b-button v-if="needle.length> 0"
                @click="resetSearch"
                variant="outline">
        <Icon type="remove"></Icon>
      </b-button>
    </div>
  </sliceheadline>
  <template v-if="result && sorted.length > 0">
    <DirectoryListings dense
                       simple
                       :items="sorted">
      <b-alert show
               variant="light">Found <b>{{sorted.length}}</b> results for
        <b>{{queryNeedle}}</b>
      </b-alert>
    </DirectoryListings>
  </template>
  <Slice>
    <b-alert show
             variant="warning"
             v-if="result && sorted.length == 0">
      <prismic-rich-text :field="item.data.no_results_message" />
    </b-alert>
    <b-spinner v-if="isSearching"></b-spinner>
  </Slice>

</div>
</template>

<script>
import multisort from "multisort"
import DirectoryListings from "@/components/ui/DirectoryListings"
import Icon from "@/components/ui/Icon"
export default {
  name: "Search",
  props: [],
  components: {
    Icon,
    DirectoryListings
  },
  created() {
    this.getContent()
  },
  data() {
    return {
      item: null,
      result: null,
      isSearching: false,
      needle: ''
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        if ( this.queryNeedle ) {
          this.needle = this.queryNeedle
          this.updateNeedle()
        }
        this.getContent()

      }
    },
    'item': {
      immediate: true,
      handler() {
        this.currentType = this.uid
      }
    },
  },
  computed: {
    queryNeedle() {
      if ( this.$route.params && this.$route.params.needle ) {
        return this.$route.params.needle
      }
      return null
    },
    sorted() {
      let ar = this.result.results.map( a => {
        if ( a.data.release_date ) {
          return a
        } else {
          a.data.release_date = a.first_publication_date
          return a
        }
      } )

      var criteria = [
        '~data.pinned',
        '~data.release_date',
      ];

      return multisort( ar, criteria )
    }
  },
  methods: {
    getContent() {
      this.$prismic.client.getSingle( 'search', {
        lang: this.language,
      } ).then( response => {
        if ( response ) {
          this.item = response
        }
      } );
    },
    updateNeedle() {
      if ( this.queryNeedle != this.needle ) {
        this.$router.push( "/" + this.language + "/search/" + this.needle )
      } else {
        this.searchDatabase()
      }
    },
    searchDatabase() {
      this.isSearching = true
      this.$prismic.client.query( [
        this.$prismic.Predicates.fulltext( 'document', this.needle )
      ], {
        pageSize: 100,
        lang: this.language
      } ).then( ( response ) => {
        this.result = response
        this.isSearching = false
      } );
    },
    resetSearch() {
      this.needle = ''
      this.updateNeedle()
      this.result = null
    }


  }
};
</script>
<style scoped lang="scss">
.search {
    display: grid;
    grid-gap: 5rem;

    .tools {
        @include grid-h;
        grid-gap: var(--p4);
        grid-template-columns: 1fr auto auto;
        align-items: center;
        justify-content: start;
        align-content: center;
    }
}
</style>
