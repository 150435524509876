<template>
<div class="nav-item"
     :class="['level'+level, {'selected':isSelected}]"
     v-if="me.link && link">


  <b-link v-if="isDocument && link"
          class="cell"
          :to="link">
    <span :class="highlight?'highlight':''">{{text}}</span>
  </b-link>

  <b-link v-else-if="link"
          class="cell"
          :href="link"
          :target="target">
    <span>{{text}}</span>
  </b-link>

  <div class="items"
       v-show="isSelected || childSelected || footer">
    <template v-if="hasChildren">
      <NavItem v-for="subitem in item.items"
               name="navitem"
               :footer="footer"
               @child-selected="handleChildSelected"
               :key="subitem.uid"
               :level="2"
               :item="subitem" />
    </template>
  </div>



</div>
</template>

<script>
import linkResolver from "@/prismic/linkResolver.js"
export default {
  name: "NavItem",
  components: {},
  props: {
    item: Object,
    selected: Object,
    footer: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      childSelected: false,
    }
  },
  watch: {
    "$route.path": {
      handler() {
        this.childSelected = false
      }
    }
  },
  computed: {
    isHome() {
      return this.$route.meta.isHome
    },
    isDocument() {
      return this.me.link.link_type == 'Document'
    },
    isWeb() {
      return this.me.link.link_type == 'Web'
    },
    isSelected() {
      if ( this.footer ) {
        return false
      }
      let s = this.$route.path == this.link
      if ( s ) {
        this.$emit( 'child-selected', s )
      }
      return s
    },
    hasChildren() {
      return this.item.items && this.item.items.length > 0
    },
    me() {
      return this.level == 1 ? this.item.primary : this.item
    },
    text() {
      return this.me.text
    },
    link() {
      if ( this.isDocument ) {
        return linkResolver( this.me.link )
      } else if ( this.isWeb ) {
        return this.me.link.url
      } else {
        return null
      }
    },
    target() {
      return this.me.link.target
    }
  },
  methods: {
    select( thing ) {
      this.$emit( 'on-select', thing )
    },
    handleChildSelected( val ) {
      this.childSelected = val
    }
  }
};
</script>
<style scoped lang="scss">
.nav-item {
    display: grid;
    align-items: start;
    align-content: start;

    &.selected > .cell {
        background: theme-color-level("danger", 0);
        @include swoosh-long;
        color: white;
        text-decoration: none !important;

        span {
            color: inherit;
        }
    }

    .cell {
        cursor: pointer;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        user-select: none;
        padding: var(--p1) var(--p2);
    }

    &.level1 {

        > .cell {
            font-weight: bold;
        }

    }

    &.level2 {
        font-size: 0.9rem;
    }

    .mobile & {

        .cell {
            padding: var(--p3) var(--p4);
        }
        font-size: var(--fxl);
    }
    .highlight {
      margin-top: var(--p3);
      color: $danger;
    }

}
</style>
