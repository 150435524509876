export default {
  watch: {
    'language': {
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal != oldVal){
          this.getNavItems()
        }
      }
    },
  },
  methods: {
    getNavItems() {
      this.$prismic.client.getSingle(
          'navigation', {
            fetchLinks: [ 'page.title' ],
            lang: this.language,
          } )
        .then( ( document ) => {
          this.$store.dispatch("updateNav", document.data.body)
          if(document.data.header_buttons && document.data.header_buttons[0]){
            this.$store.dispatch("updateHeader", document.data.header_buttons)
          }
          if(document.data.search && document.data.search[0]){
            this.$store.dispatch("updateSearch", document.data.search[0])
          }
          if(document.data.newsletter_label && document.data.newsletter_label[0].text.length>0) {
            this.$store.dispatch("updateNewsletterLabel", document.data.newsletter_label)
          }
          if(document.data.newsletter_page && document.data.newsletter_page.id){
            this.$store.dispatch("updateNewsletterPage", document.data.newsletter_page)
          }
        } );

        this.$prismic.client.getSingle(
          'newsletterbanner', {
            fetchLinks: ['page.title'],
            lang: this.language,
          } )
          .then( (document ) => {
            this.$store.dispatch("updateNewsletterBanner", document.data);
          });
    }
  }
};
