<template>
<div class="textview"
     :class="{'flat': flat}">
  <div v-for="group in asHeadingsAndColumns"
       :key="group.id"
       :class="[group[0].isHeading ? 'other' :'paragraph']">
    <prismic-rich-text class="prismic"
                       :field="group" />
  </div>
</div>
</template>

<script>
export default {
  name: "TextView",
  props: {
    text: [ Object, Array ],
    flat: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  computed: {
    asHeadingsAndColumns() {
      let result = []
      let temp = []
      let previous = null

      for ( let item of this.text ) {
        let isHeading = item.type.startsWith( 'heading' ) ? true : false
        let isImage = item.type.startsWith( 'block-image' ) ? true : false
        item[ 'isHeading' ] = isHeading
        item[ 'isImage' ] = isImage

        if ( isHeading !== previous ) {
          if ( temp.length > 0 ) {
            result.push( temp )
            temp = []
          }
          temp.push( item )
          previous = isHeading
        } else {
          temp.push( item )
        }
      }

      if ( temp.length > 0 ) {
        result.push( temp )
      }

      return result
    },
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.textview {

    &:not(.flat) {
        .paragraph {
            margin-left: var(--p6);
        }
    }

    .paragraph + .paragraph {
        margin-top: var(--p6);
    }

    .other {
        margin: 2rem 0;
    }

    /deep/ ul {
        li {
            //font-style: italic;
            list-style: disc;
        }
    }

    /deep/ pre {
        column-count: 1;
        column-fill: auto;
        display: block;
        white-space: normal;
        border-radius: 0;
        color: inherit;
        font-family: inherit;
        font-size: 1rem;
        font-style: italic;
        margin-left: 2rem;
        background: none !important;
    }

    .desktop & {
        /deep/ .block-img {
            float: right;
            margin: 2rem;
            img {
                max-width: 20rem;
                //@include swoosh-long;
            }
        }
        $heading-scale: 4;
        @for $i from 1 through 6 {
            .prismic /deep/ h#{$i} {
                font-weight: normal;
                font-size: $base-font-size + ($heading-scale * (6 - $i));
            }
        }
    }

    .tablet & {
        .paragraph {
            margin-left: 0;
        }

        /deep/ .block-img {
            img {
                max-width: 100%;
                //@include swoosh-long;
            }
        }

        $heading-scale: 3;
        @for $i from 1 through 6 {
            .prismic /deep/ h#{$i} {
                font-weight: normal;
                font-size: $base-font-size + ($heading-scale * (6 - $i));
            }
        }

    }
    .mobile & {
        .paragraph {
            margin-left: 0;
        }

        /deep/ .block-img {
            img {
                max-width: 100%;
                //@include swoosh-long;
            }
        }

        $heading-scale: 2;
        @for $i from 1 through 6 {
            .prismic /deep/ h#{$i} {
                font-weight: normal;
                font-size: $base-font-size + ($heading-scale * (6 - $i));
            }
        }

    }

}
</style>
