<!-- ////////////////////////////////////////////// -->
<!-- ////////////////  HTML //////////////////////// -->
<!-- ////////////////////////////////////////////// -->
<template>
<component class="icon"
           :class="type"
           :id="type  "
           ref="icon"
           :alt="alt"
           :aria-label="alt"
           :is="type" />
</template>

<!-- ////////////////////////////////////////////// -->
<!-- ///////////////  JAVASCRIPT ///////////////////-->
<!-- ////////////////////////////////////////////// -->
<script>
import Add from '@/assets/Add.svg'
import Arrow from '@/assets/Arrow.svg'
import ArrowLeft from '@/assets/ArrowLeft.svg'
import Brush from '@/assets/Brush.svg'
import BrushTop from '@/assets/BrushTop.svg'
import BrushLeft from '@/assets/BrushLeft.svg'
import Check from '@/assets/Check.svg'
import Driving from '@/assets/Driving.svg'
import Download from '@/assets/Download.svg'
import External from '@/assets/External.svg'
import Good from '@/assets/Good.svg'
import Home from '@/assets/Home.svg'
import List from '@/assets/List.svg'
import Location from '@/assets/Location.svg'
import Logo from '@/assets/Logo.svg'
import LogoKO from '@/assets/Logo-KO.svg'
import MarkerLine from '@/assets/Line.svg'
import Menu from '@/assets/Menu.svg'
import More from '@/assets/More.svg'
import Remove from '@/assets/Remove.svg'
import Search from '@/assets/Search.svg'
import Settings from '@/assets/Settings.svg'
import Time from '@/assets/Time.svg'
import Transit from '@/assets/Transit.svg'
import USDA from '@/assets/USDA.svg'
import Walking from '@/assets/Walking.svg'
import Warning from '@/assets/Warning.svg'

//Social Media Icons
import Facebook from '@/assets/Facebook.svg'
import Twitter from '@/assets/Twitter.svg'
import Youtube from '@/assets/Youtube.svg'
import Instagram from '@/assets/Instagram.svg'

//Languages
import enus from '@/assets/en-us.svg'
import esmx from '@/assets/es-mx.svg'
import es from '@/assets/es.svg'
import zhcn from '@/assets/zh-cn.svg'
import ru from '@/assets/ru.svg'

export default {
  name: 'Icon',
  components: {
    Add,
    Arrow,
    ArrowLeft,
    Brush,
    BrushTop,
    BrushLeft,
    Check,
    Driving,
    Download,
    External,
    Good,
    Home,
    List,
    Location,
    Logo,
    LogoKO,
    MarkerLine,
    Menu,
    More,
    Remove,
    Search,
    Settings,
    Time,
    Transit,
    USDA,
    Walking,
    Warning,
    Facebook,
    Twitter,
    Youtube,
    Instagram,
    enus,
    esmx,
    es,
    zhcn,
    ru
  },
  props: {
    type: String,
  },
  computed: {
    alt() {
      return this.type
    }
  }
}
</script>

<!-- ////////////////////////////////////////////// -->
<!-- ////////////////  CSS /////////////////////////-->
<!-- ////////////////////////////////////////////// -->
<style scoped lang="scss">
.icon {
    height: inherit;
    width: inherit;
    color: inherit;

    * {
        color: inherit;
        shape-rendering: geometricPrecision;
    }
}
</style>
