<template>
<div class="page"
     v-if="item">
  <component v-if="uid === 'mlk-serve-a-thon'" :is="'slice'">
    <div class="wrapper-container">
      <div style="position: relative; width: 75%; height: 0; padding-top: 42.1875%; padding-bottom: 0; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden; border-radius: 8px; will-change: transform;">
        <iframe loading="lazy" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"
                src="https://www.canva.com/design/DAF6Fak8LRY/view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
        </iframe>
      </div>
      <a href="https://www.canva.com/design/DAF6Fak8LRY/view?utm_content=DAF6Fak8LRY&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link" target="_blank" rel="noopener">MLK Serve-a-thon Slideshow</a> by Tenzin Choetso
    </div>
  </component>
  <component v-for="slice in body"
             :key="slice.id"
             :is="'slice'+slice.slice_type"
             :item="item"
             :slice="slice">
  </component>


</div>
</template>

<script>
export default {
  name: "Page",
  props: [ 'uid', 'type', 'lang' ],
  data() {
    return {
      item: null
    }
  },
  watch: {
    'uid': {
      immediate: true,
      handler() {
        this.getContent()
      }
    }
  },
  computed: {
    needsHeader() {
      let first_slice = this.item.data.body[ 0 ]
      if ( first_slice && first_slice.slice_type == "header" ) {
        return false
      }
      return true
    },
    body() {
      if ( this.needsHeader ) {
        return [ {
          slice_type: "headline",
          tone: 'normal'
        }, ...this.item.data.body ]
      }
      return this.item.data.body
    }
  },
  methods: {
    getContent() {
      let type = this.type || 'page'
      this.$prismic.client.getByUID( type, this.uid, {
        lang: this.language,
      } ).then( response => {
        if ( response ) {
          this.item = response
        } else {
          this.$router.replace( "/not-found" )
        }
      } );
    }
  }
};
</script>
<style scoped lang="scss">
.page {
    display: grid;
    grid-gap: 2rem;
}
</style>
