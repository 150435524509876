<template>
<div class="newsletters"
     v-if="item">
  <sliceheadline :item="item" />

  <b-container>
    <div class="menu">
      <div class="choices">
        <NewsletterItem v-for="newsletter in newsletters"
                        :key="newsletter.id"
                        :item="newsletter.newsletter"
                        @opted="handleOpted" />
      </div>

      <div class="form"
           v-if="opted.length > 0">
        <!-- {{all}} -->
        <!-- <h3> Sign up for {{opted.length}} newsletters</h3> -->
        <b-form-group label="First Name">
          <b-input v-model="name"
                   placeholder="Your name..."
                   type="text"></b-input>
        </b-form-group>
        <b-form-group label="Last Name">
          <b-input v-model="lastname"
                   placeholder="Your last name..."
                   type="text"></b-input>
        </b-form-group>
        <b-form-group label="Email address"
                      v-if="requires_email">
          <b-input v-model="email"
                   placeholder="Send email to..."
                   type="text"></b-input>
        </b-form-group>
        <b-form-group label="Mobile number"
                      v-if="requires_text">
          <b-input v-model="text"
                   placeholder="Send text messages to"
                   type="text"></b-input>
        </b-form-group>
        <b-form-group label="Mailing address"
                      v-if="requires_mail">
          <b-input v-model="mail"
                   placeholder="Send paper mail to"
                   type="text"></b-input>
        </b-form-group>
        <b-button v-if="!subscribed"
          @click="subscribeToMailchimpList">Sign up</b-button>
        <b-button v-else>Thank You For Subscribing!</b-button>

      </div>
    </div>
  </b-container>

  <!-- <b-input v-model="needle"
             @keydown.native.enter="search"
             type="text"></b-input>
    <section v-if="result">
      <h4>Found {{result.total_results_size}} matches</h4>
      <PostItem v-for="item in result.results"
                :key="item.uid"
                :item="item" />
    </section> -->

</div>
</template>

<script>
import Vue from "vue";
import NewsletterItem from "@/components/ui/NewsletterItem"
import jsonp from "jsonp";
import queryString from "query-string";
export default {
  name: "Newsletters",
  props: [],
  mixins: [ Vue ],
  components: {
    NewsletterItem
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.getContent()
      }
    }
  },
  data() {
    return {
      item: null,
      all: [],
      email: null,
      text: null,
      mail: null,
      name: null,
      lastname: null,
      subscribed: false,
      isSubscribing: false,
    }
  },
  computed: {
    // text() {
    //   return this.item.data.intro.concat( this.item.data.full_bio )
    // }
    newsletters() {
      return this.item.data.newsletters
    },
    opted() {
      return this.all.filter( a => a.opted )
    },
    requires_email() {
      return this.all.some( a => a.email_delivery )
    },
    requires_mail() {
      return this.all.some( a => a.mail_delivery )
    },
    requires_text() {
      return this.all.some( a => a.text_delivery )
    },
  },
  methods: {
    getContent() {
      this.$prismic.client.getSingle( 'newsletters', {
        lang: this.language,
        fetchLinks: [ 'newsletter.text_delivery', 'newsletter.mail_delivery', 'newsletter.email_delivery', 'newsletter.mailchimp_id','newsletter.user_id','newsletter.url', 'newsletter.description', 'newsletter.title' ]
      } ).then( response => {
        if ( response ) {
          this.item = response
          // console.log(this.item)
        }
      } );
    },
    handleOpted( val ) {
      let exists = this.all.find( a => a.id == val.id )
      if ( !exists && val.opted ) {
        this.all.push( val )
      } else {
        let index = this.all.map( a => a.id ).indexOf( val.id )
        this.$set( this.all, index, val )
      }
    },
    async subscribeToMailchimpList() {
      this.isSubscribing = true;
      //https://agency.us17.list-manage.com/subscribe?u=a38e2083a5aa7d5750ec5184b&id=0398d0cf91
      const email = this.email;
      const firstName = this.name;
      const lastName = this.lastname;
      const address = this.mail;
      const phone = this.text;
      const newsletters = this.item.data.newsletters;

      let userData = queryString.stringify({
        EMAIL: email,
        FNAME: firstName,
        LNAME: lastName,
        ADDRESS: address,
        PHONE: phone
      });
      for (let i = 0; i < newsletters.length; i++) {
        let listData = queryString.stringify({
          u: newsletters[i].newsletter.data.user_id,
          id: newsletters[i].newsletter.data.mailchimp_id,
        });
        if (this.opted[i] && this.opted[i].email_delivery) {
          const post_url = `${newsletters[i].newsletter.data.url}?${listData}&${userData}`;
          // console.log(post_url);
          jsonp(post_url, {param: "c"});
        }
      }

      this.subscribed = true
      this.isSubscribing = false
    },

  }
};
</script>
<style scoped lang="scss">
.newsletters {
    display: grid;
    grid-gap: 5rem;
    .menu {
        // @include grid-h;
        // grid-template-columns: 1fr;
    }

    section {
        height: 2rem;
        background: pink;
        width: 100%;
        position: fixed;
        bottom: 0;
    }
}
</style>
