<template>
<div class="attribution"
     :class="isPressRelease ? 'press_release' : 'normal'">

  <b-link v-if="bio && bioPortrait"
          :to="bioLink">
    <b-img :src="bioPortrait"
           :alt="bioFullName" />
  </b-link>

  <div v-if="bio"
       class="details">
    <b-link :to="bioLink">
      <b>{{bioFullName}}</b>
      <span v-if="bioQualifications"
            class="qualifications">{{bioQualifications}}</span>
    </b-link>
    <template v-if="isPressRelease">
      <b-link v-if="bioEmail"
              :href="`mailto:${bioEmail}`">{{bioEmail}}</b-link>
      <b-link v-if="bioTelephone"
              :href="`tel:${bioTelephone}`">{{bioTelephone}}</b-link>
    </template>
  </div>

  <div v-if="datePosted">{{datePosted}}</div>


</div>
</template>

<script>
import moment from "moment"
export default {
  name: "Attribution",
  props: {
    'item': Object,
  },
  data() {
    return {
      bio: false
    }
  },
  components: {},
  computed: {
    isPressRelease() {
      return this.item.type == 'press_release'
    },
    bioFullName() {
      return this.$prismic.richTextAsPlain( this.bio.data.title )
    },
    bioQualifications() {
      let q = this.bio.data.qualifications
      if ( q ) {
        return ', ' + q
      }
      return null
    },
    bioLink() {
      return `/${this.language}/bio/${this.bio.uid}`
    },
    bioEmail() {
      return this.bio.data.email
    },
    bioTelephone() {
      return this.bio.data.telephone
    },
    bioPortrait() {
      return this.bio.data.portrait.square ? this.bio.data.portrait.square.url : false
    },
    datePosted() {
      if ( [ 'blog_post', 'research', 'career', 'testimonial', 'video', 'survey' ].includes( this.item.type ) ) {

        return moment( this.item.data.release_date || this.item.first_publication_date ).format( "MMM D, YYYY" )
      }
      return false
    },
  },
  created() {
    this.getBio()
  },

  methods: {
    getBio( lang ) {
      if ( this.item.data.attribution && this.item.data.attribution.uid ) {
        this.$prismic.client.getByUID( 'bio', this.item.data.attribution.uid, {
          lang: lang || this.language
        } ).then( response => {
          if ( response ) {
            this.bio = response
          }
        } )
      }
    }
  }
};
</script>
<style scoped lang="scss">
.attribution {
    display: grid;
    justify-self: start;
    align-items: center;
    grid-template-columns: auto auto auto;
    grid-gap: 0.5rem;

    .details {
        @include grid-h;
        grid-gap: 0.5rem;
        align-items: center;
        align-content: center;
        justify-self: start;

        .mobile & {
            @include grid-v;
        }

        a {
            color: inherit;
        }
    }

    img {
        width: 2rem;
        height: 2rem;
        background-color: theme-color-level(primary,-11);
        mask-image: url("~@/assets/Swoosh.png");
        mask-size: 100% 100%;
    }

    .qualifications {
        font-size: 0.8rem;
        opacity: 1;
    }

    .other_title {
        font-size: 0.7rem;
        font-style: italic;
    }
}
</style>
