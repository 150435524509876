<template>
<Slice>
  <figure class="slice-visual">
    <b-img :alt="slice.primary.visual.alt"
           :src="slice.primary.visual.url"
           :class="edges" />
    <figcaption>{{slice.primary.visual.alt}}</figcaption>
  </figure>
</Slice>
</template>

<script>
export default {
  name: "SliceVisual",
  props: [ 'item', 'slice' ],
  components: {},
  computed: {
    edges() {
      if ( this.slice.primary.edges === true ) {
        return 'clean'
      }
      return 'distressed'
    }
  },
  methods: {}
};
</script>
<style scoped lang="scss">
figure.slice-visual {
    display: grid;
    grid-gap: 1rem;

    img {
        display: grid;
        width: 100%;
        margin: 0 auto;
        height: auto;
        max-height: 50vh;
        object-fit: cover;
        border-radius: 1rem;
        overflow: hidden;

        &.clean {}

        &.distressed {
            @include swoosh;
        }

    }

    figcaption {
        padding: 0 1rem;
    }

    .mobile & img {
        border-radius: 0;
    }

}
</style>
