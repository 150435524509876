export const locales = [
    {
      name: 'English',
      localizedName: 'English',
      code: 'en-us',
      default: true
    },
    {
      name: 'Arabic',
      localizedName: 'عربى',
      code: 'ar',
    },
    {
      name: 'Bengali',
      localizedName: 'বাংলা',
      code: 'bn',
    },
    {
      name: 'Creole',
      localizedName: 'Kreyòl',
      code: 'ht',
    },
    {
      name: 'Chinese',
      localizedName: '中文',
      code: 'zh',
    },
    {
      name: 'French',
      localizedName: 'Francais',
      code: 'fr',
    },
    {
      name: 'Hindi',
      localizedName: 'हिंदी',
      code: 'hi',
    },
    {
      name: 'Italian',
      localizedName: 'Italiano',
      code: 'it',
    },
    {
      name: 'Korean',
      localizedName: '한국어',
      code: 'ko-kr',
    },
    {
      name: 'Polish',
      localizedName: 'Polski',
      code: 'pl',
    },
    {
      name: 'Russian',
      localizedName: 'Pусский',
      code: 'ru',
    },
    {
      name: 'Ukrainian',
      localizedName: 'українська',
      code: 'uk',
    },
    {
      name: 'Spanish',
      localizedName: 'Español',
      code: 'es',
    },
    {
      name: 'Swahili',
      localizedName: 'kiSwahili',
      code: 'sw',
    },
    {
      name: 'Tagalog',
      localizedName: 'Tagalog',
      code: 'tl',
    },
    {
      name: 'Vietnamese',
      localizedName: 'Tiếng Việt',
      code: 'vi',
    },
    {
      name: 'Wolof',
      localizedName: 'Wolof',
      code: 'wo',
    },
    {
      name: 'Yiddish',
      localizedName: 'ייִדיש',
      code: 'yi',
    }
  ]