<template>
<Slice>
  <b-container class="slice-small-action">

    <section class="content">

      <div v-if="hasMainHeadline"
           class="main-headline">
        <h2 class="smallactionheader">{{slice.primary.headline}}</h2>
      </div>

      <div v-if="hasMainText"
           class="main-description">
        <TextView :text="slice.primary.description"
                  flat />
      </div>
      <div class="actions"
           v-if="hasActions">
        <SuperButton variant="danger"
                     :link="link">
          <p>{{label}}</p>
          <Icon type="arrow"></Icon>
        </SuperButton>
      </div>
    </section>

  </b-container>
</Slice>
</template>

<script>
import TextView from "@/components/ui/TextView"
export default {
  name: "SliceSmallAction",
  props: [ 'slice' ],
  components: {
    TextView
  },
  computed: {
    hasMainHeadline() {
      return this.slice.primary.headline ? true : false
    },
    hasMainText() {
      if ( this.slice.primary.description ) {
        let t = this.slice.primary.description.reduce( ( acc, x ) => acc + x.text.length, 0 );
        return this.slice.primary.description.length > 0 && t > 0 ? true : false
      }
      return false
    },
    hasActions() {
      return this.label ? true : false
    },
    label() {
      if ( this.slice.slice_type == "petition" ) {
        return "Petition"
      } else {
        return this.slice.primary.button_label
      }
    },
    link() {
      return this.slice.primary.button_link
    }
  }
};
</script>
<style scoped lang="scss">
.slice-small-action {
    display: grid;
    grid-template-columns: 1fr;
    overflow: hidden;
    padding: var(--pxxxl) var(--pxs) var(--pxxxl) ;
    section.content {
        display: grid;
        grid-template-columns: 5fr 2fr;
        grid-template-areas: "headline actions" "description actions";
        grid-column-gap: 5rem;
        align-items: center;

        .actions {
            grid-area: actions;
        }

        .headline {
            grid-area: headline;
        }
        .main-description {
            grid-area: description;
        }
    }

    .actions {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--p1);

        .action {
            display: grid;
            grid-gap: 1rem;
            justify-content: start;
            justify-items: start;
            align-items: start;
            align-content: start;

            .super-button {
                grid-area: action;
            }

            &.hasDescription {
                grid-template-areas: "text action";
                grid-template-columns: 1fr 1fr;
                grid-gap: var(--p6);
                padding-bottom: var(--p4);

                .text {
                    grid-area: text;

                    /deep/ > *:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &.noDescription {
                grid-template-areas: "action";
                grid-template-columns: 1fr;
                grid-gap: 0;
            }
        }

        .mobile & {
            .action {
                grid-template-areas: "text" "action";
                grid-template-columns: 1fr;
                grid-auto-flow: row;
                padding-bottom: var(--p4);
            }
        }
    }

    .mobile & {

      grid-template-columns: 1fr;
      padding: 3rem 2rem;

      section.content {
          grid-template-columns: 1fr;
          grid-template-rows: auto;
          grid-auto-rows: auto;
          grid-template-areas: "headline" "description" "actions";
      }
    }

}
</style>
