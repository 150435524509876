<template>
<Slice>
  <b-container class="slice-table">

    <header v-if="hasHeadline">
      <div class="main-headline">
        {{slice.primary.headline}}
      </div>
    </header>

    <table class="table">
      <tr v-if="hasHeaders">
        <th class="col"
            v-for="col in asCSV.meta.fields"
            :key="col.id">
          <h6 class="smallcaps">{{col}}</h6>
        </th>
      </tr>
      <tr class="item"
          v-for="item in asCSV.data"
          :key="item.id">

        <td v-for="col in item"
            :key="col.id">
          <vue-simple-markdown :source="col"></vue-simple-markdown>
        </td>
      </tr>
    </table>

    <div class="mobile-table">
      <div class="item"
           v-for="item in asCSV.data"
           :key="item.id">
        <div v-for="(col, position) in item"
             class="row"
             :key="col.id">
          <div class="col-label"
               v-if="asCSV.meta.fields">
            {{position}}
          </div>

          <div class="col-value">
            <vue-simple-markdown :source="col"></vue-simple-markdown>
          </div>
        </div>
      </div>
    </div>

    <footer>
      <b-button size="sm"
                class="outline"
                aria-label="Download as CSV"
                @click="downloadAsCSV">Download as CSV</b-button>
    </footer>

  </b-container>
</Slice>
</template>

<script>
import Papa from "papaparse"
export default {
  name: "SliceTable",
  props: [ 'item', 'slice' ],
  components: {},
  computed: {
    hasHeadline() {
      return this.slice.primary.headline ? true : false;
    },
    hasHeaders() {
      return this.slice.primary.includes_headers ? true : false
    },
    hasCSV() {
      return this.slice.primary.csv_data.length > 0
    },
    asCSV() {
      // Parse CSV string
      return Papa.parse( this.slice.primary.csv_data[ 0 ].text, {
        header: this.slice.primary.includes_headers
      } );
    },
  },
  methods: {
    downloadAsCSV() {
      let anchor = document.createElement( 'a' );
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent( this.slice.primary.csv_data[ 0 ].text );
      anchor.target = '_blank';
      anchor.download = this.slice.primary.headline || 'Downloaded CSV @ ' + ( new Date() ) + '.csv';
      anchor.click();
    }
  }
};
</script>
<style scoped lang="scss">
.slice-table {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    padding: 2rem;
    border-radius: 1rem;
    overflow: hidden;
    background: theme-color-level(primary, -11.9);

    .main-headline {
        font-size: 2rem;
        margin-left: 1rem;
    }

    .table {
        table-layout: fixed;
        margin: 0;

        th {
            @include smallcaps;
            border: none;
        }

        td {
            word-wrap: break-word;
            word-break: normal;

        }

        tr:first-of-type {
            td {
                border: none;
            }
        }

    }

    .mobile-table {
        display: grid;
        grid-template-columns: 1fr;

        .item {
            display: grid;
            border-top: 4px solid theme-color-level(primary, -8);
            padding: var(--p4);
            .row {
                display: grid;
                grid-template-columns: 1fr 1fr;
                border-top: 1px solid theme-color-level(primary, -10);

                .col-value {
                    word-wrap: break-word;
                    word-break: normal;
                }

                .col-label {
                    @include smallcaps;
                    opacity: 0.5;
                }
                &:first-of-type {
                    border: none;
                }
            }

        }
    }

    .mobile & {
        .table {
            display: none;
        }
    }

    .desktop &,
    .tablet & {
        .mobile-table {
            display: none;
        }
    }

    .markdown-body /deep/ {
        a {
            text-decoration: underline;
        }
    }

}
</style>
