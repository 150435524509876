<template>
<Slice fluid>
  <div class="slice-header"
       :class="[tone]">
    <b-container class="wrapper">
      <div class="slice-header-card"
           v-if="hasActions || hasHeadline">
        <div class="side">
          <div class="headline">
            <h1>{{slice.primary.headline}}</h1>
            <Swoosh :uid="item.uid"
                    animate />
          </div>
          <h2 class="description display"
              v-if="hasSubheadline">
            {{slice.primary.subheadline}}
          </h2>
          <div class="actions"
               v-if="hasActions">
            <SuperButton v-for="item in actions"
                         :variant="buttonVariant"
                         :link="item.link"
                         :key="item.id">
              <p>{{item.call_to_action}}</p>
              <Icon :type="iconType(item)"></Icon>
            </SuperButton>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</Slice>
</template>

<script>
import Swoosh from "@/components/ui/Swoosh"
export default {
  name: "SliceHeader",
  props: [ 'item', 'slice' ],
  components: {
    Swoosh
  },
  computed: {
    hasActions() {
      return this.actions.length > 0
    },
    hasHeadline() {
      return this.slice.primary.headline ? true : false
    },
    hasSubheadline() {
      return this.slice.primary.subheadline ? true : false
    },
    actions() {
      return this.slice.items.filter( i => i.call_to_action !== null )
    },
    tone() {
      return this.slice.primary.tone
    },
    buttonVariant() {
      return this.tone == 'normal' ? 'primary' : 'danger'
    }
  },
  methods: {
    actionHref( actionItem ) {
      if ( actionItem.link.link_type == 'Document' ) {
        return actionItem.link.uid
      } else {
        return actionItem.link.url
      }
    },
    actionTarget( actionItem ) {
      if ( actionItem.link.link_type == 'Web' && actionItem.link.target == '_blank' ) {
        return true
      } else {
        return false
      }
    },
    iconType( actionItem ) {
      if ( actionItem.link.link_type == 'Web' ) {
        if ( actionItem.link.target == '_blank' ) {
          return 'External'
        }
      }
      return 'Arrow'
    }
  }
};
</script>
<style scoped lang="scss">
.slice-header {
    display: grid;
    background: theme-color-level(primary,-10);
    grid-template-rows: 1fr;
    align-items: center;
    overflow: hidden;
    padding: var(--pxxxl);

    // Which mask do we use?
    .desktop & {
        @include swoosh-long;
        min-height: 40vh;
    }

    .tablet & {
        @include swoosh-long;
    }

    .mobile & {
        // No mask
        .wrapper {
            padding: 0;
        }
    }

    // Which color scheme do we use?

    &.urgent {
        background: linear-gradient(0deg, theme-color-level(danger, -3) 0%, theme-color-level(danger, 2) 100%);
        * {
            color: white;
        }
    }
    &.normal {
        background: linear-gradient(90deg, theme-color-level(emphasis, -11) 0%, theme-color-level(emphasis, -11.5) 100%);
        * {
            color: $primary;
        }
    }
    &.important {
        background: linear-gradient(0deg, theme-color-level(primary, -3) 0%, theme-color-level(primary, 2) 100%);
        * {
            color: white;
        }
    }

    .slice-header-card {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas: "text";
        align-items: center;
        align-self: center;
        justify-self: center;
        z-index: 100;

        .side {
            grid-area: text;
            display: grid;
            align-content: center;
            justify-content: start;
            grid-gap: 1rem;

            .swoosh {
                grid-area: line;
                height: auto;
                width: 60%;
                color: inherit;
            }

            .actions {
                display: grid;
                grid-gap: var(--p1);
                justify-self: end;
                align-content: center;
                align-items: center;
            }

            .desktop & {
                grid-template-columns: 2fr 1fr;
                .headline {
                    grid-column: 1;
                }
                .description {
                    grid-column: 1;
                }
                .actions {
                    grid-column: 2;
                    grid-row: 1 / span 2;
                    justify-self: start;
                }
            }

            .tablet & {
                grid-gap: var(--pxxl);
                .actions {
                    justify-self: start;
                    grid-gap: var(--pxl);
                }
            }

            .mobile & {
                grid-gap: var(--pxxxl);
                
                .swoosh {
                    width: 80%;
                }
                .actions {
                    justify-self: start;
                    grid-gap: var(--pxl);
                }
            }

        }

    }

}
</style>
