<template>
<Slice>
  <DirectoryListings clean
                     :items="slice.items.map(a => a.item)" />
</Slice>
</template>

<script>
import DirectoryListings from "@/components/ui/DirectoryListings"
export default {
  name: "SliceFeatured",
  props: [ 'item', 'slice', 'url' ],
  components: {
    DirectoryListings
  },
  data() {
    return {}
  },
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
.slice {}
</style>
