<template>
<div class="press-release-info"
     v-if="item">

  <h6>
    <span class="immediate"
          v-if="isInPast || !hasReleaseDate">For immediate release</span>
    <span class="future"
          v-else>For release on:</span>
    <span v-if="hasReleaseDate"> {{date}}</span>
  </h6>

</div>
</template>

<script>
import moment from "moment"
export default {
  name: "PressReleaseInfo",
  props: [ 'item' ],
  components: {},
  computed: {
    date() {
      return moment( this.item.data.release_date ).format( "MMM D, YYYY" )
    },
    isInPast() {
      if ( this.hasReleaseDate ) {
        return moment().diff( this.item.data.release_date, 'days' ) > 0
      }
      return null
    },
    hasReleaseDate() {
      return this.item.data.release_date
    }
  },
  methods: {

  }
};
</script>
<style scoped lang="scss">
.press-release-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: start;
    align-items: start;
    grid-gap: 2rem;

    h6 {
        @include grid-v;
        grid-gap: 0;
        .future,
        .immediate {
            text-transform: uppercase;
        }
    }

}
</style>
