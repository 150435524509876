<template>
<div class="home"
     v-if="item">

  <component v-for="slice in body"
             :key="slice.id"
             :is="'slice'+slice.slice_type"
             :item="item"
             :slice="slice">
  </component>

</div>
</template>

<script>
export default {
  name: "Home",
  props: [ 'uid' ],
  data() {
    return {
      item: null,
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.getContent()
      }
    }
  },
  computed: {
    body() {
      return this.item.data.body
    }
  },
  methods: {
    getContent( lang ) {
      this.$prismic.client.getSingle( 'home', {
        lang: lang || this.language
      } ).then( response => {
        if ( response ) {
          this.item = response
        }
      } )
    },
  }
};
</script>
<style scoped lang="scss">
.home {
    display: grid;
    grid-gap: 2rem;
}
</style>
